import axios from 'axios';
import * as Constants from '../Constants';
import { fetchingStart } from './view';
import { fetchBranchList } from './branch';
import { showInlineErrorMsg, showServerErrorMsg } from './common';

export const receiveSessionList = (data) => ({
    type: Constants.RECEIVE_SESSION_LIST,
    sessionList: data.data.activity_session,
    total_count: data.data.total_count
});

function fetchSessionListAPI(branch_id = 0, start = 0, count = Constants.MAX_COUNT) {



    return dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        let user_id = ''
        if (branch_id === "-1") {
            user_id = JSON.parse(localStorage.getItem('user')).user.id;
            branch_id = '';
        }
        dispatch(fetchingStart())
        return axios.get(Constants.API + 'activity_session/list?start=' + start + '&count=' + count + (branch_id ? '&branch_id=' + branch_id : '') + (user_id ? '&user_id=' + user_id : ''), axiosConfig)
            .then(response => response.data)
            .then(data => dispatch(receiveSessionList(data)))
            .catch(function (error) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            })
    }
}

export function fetchSessionList(branch_id = null, start = 0, count = Constants.MAX_COUNT) {
    return (dispatch) => {
        return dispatch(fetchSessionListAPI(branch_id, start, count))
    }
}

export const fetchASession = (id) => ({
    type: Constants.FETCH_A_SESSION,
    id: id
});

export const selectSessionBranch = (branch) => ({
    type: Constants.SELECT_SESSION_BRANCH,
    branch: branch
});

export const getCustomAttribute = (attributes) => ({
    type: Constants.GET_SESSION_CUSTOM_ATTRIBUTE,
    attributes: attributes
})

export function getCustomAttributeSession(branch_id, activity_id, attached_to) {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        try {
            // dispatch(fetchingStart())
            let response = await axios.get(Constants.API + 'attribute/get?attached_to=' + attached_to + '&branches=' + branch_id + '&activities=' + activity_id, axiosConfig)
            const data = response.data.data;
            dispatch(getCustomAttribute(data.attributes));
        } catch (error) {
            if (error.response.data.error.code === 1) {
                localStorage.removeItem('user');
                window.location.reload();
            }
            dispatch(showServerErrorMsg(error.response.data))

        }
    }
}

export const selectSessionActivity = (activity) => ({
    type: Constants.SELECT_SESSION_ACTIVITY,
    activity: activity
});

function upsertSessionAPI(session, action = 'update') {
    return dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        dispatch(fetchingStart())
        return axios.post(Constants.API + 'activity_session/' + action, session, axiosConfig)
            .then(response => response.data)
            .then(data => dispatch(action === 'update' ? receiveUpdateSession(data) : receiveCreateSession(data)))
            .then(action === 'update' ? alert('Successfully Updated') : '')
            .catch(function (error) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
                console.log(error.response.data);
                dispatch(showServerErrorMsg(error.response.data))
            })
    }
}

export const receiveUpdateSession = (data) => ({
    type: Constants.RECEIVE_UPDATE_SESSION,
    session: data.data.activity_session,
    message: data.data.message
});

export const receiveCreateSession = (data) => ({
    type: Constants.RECEIVE_CREATE_SESSION,
    session: data.data.activity_session,
    message: data.data.message
});

export function upsertSession(session, action = 'update') {
    let errors = {};
    if (action === 'create') {
        if (session.activity_type && session.activity_type.type === Constants.TYPE_DIRECT) {
            if (!session.activity_session_age) {
                errors.age = "Please at least fill one age information";
            }
            if (!session.australian) {
                session.australian = 0
            }
            if (!session.aboriginal) {
                session.aboriginal = 0
            }
            if (!session.torres) {
                session.torres = 0
            }
            if (!session.asti) {
                session.asti = 0
            }
            if (!session.cald) {
                session.cald = 0
            }
            if (!session.other) {
                session.other = 0
            }
            if (!session.vision_impairment) {
                session.vision_impairment = 0
            }
            if (!session.deaf) {
                session.deaf = 0
            }
            if (!session.mental_health) {
                session.mental_health = 0
            }
            if (!session.intellectual_disability) {
                session.intellectual_disability = 0
            }
            if (!session.brain_injury) {
                session.brain_injury = 0
            }
            if (!session.autism) {
                session.autism = 0
            }
            if (!session.physical_disability) {
                session.physical_disability = 0
            }
            if (!session.other_disability) {
                session.other_disability = 0
            }
        }
        if (!session.delivery_duration) {
            session.delivery_duration = 0;
        }

        if (!session.overhead_duration) {
            session.overhead_duration = 0;
        }

        if (!session.notes) {
            session.notes = ""
        }

        if (!session.volunteers) {
            session.volunteers = 0
        }
        if (!session.delivery_date) {
            var date = new Date(Date.now());
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            var moment = require('moment-timezone');
            var local = moment.tz(date.toISOString(), "Australia/Melbourne");
            session.delivery_date = local.format()
        }
        if (!session.branch_id) {
            errors.branch = 'Branch Is Required';
        }

        if (!session.activity_type_id) {
            errors.activity = 'Activity Is Required';
        }
    }
    let count = 0;
    if (session.activity_session_age) {
        for (let i = 0; i < session.activity_session_age.length; i++) {
            count += session.activity_session_age[i].count;
        }
    }
    if (session.activity_type && session.activity_type.type === Constants.TYPE_DIRECT) {
        if (session.australian + session.aboriginal + session.torres + session.asti + session.cald + session.other !== count) {
            errors.total = 'The total count does not match';
        }
    }
    if (session.attributes && session.attributes.length > 0) {
        session.custom_attributes = session.attributes;
    }

    if (session.custom_attributes && session.custom_attributes.length === 0) {
        session.attribute_activity_session = session.custom_attributes;
    }

    if (Object.keys(errors).length > 0) {
        return (dispatch) => {
            return dispatch(showInlineErrorMsg(errors))
        }
    } else {
        return (dispatch) => {
            return dispatch(upsertSessionAPI(session, action))
        }
    }
}

function deleteASessionAPI(session_id) {

    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        dispatch(fetchingStart())
        try {
            const response = await axios.get(Constants.API + 'activity_session/delete/' + session_id, axiosConfig);
            const data = response.data;
            dispatch(receiveDeleteASession(data));
            dispatch(fetchSessionList(session_id));
            dispatch(fetchBranchList());
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
        }
    }
}

export const receiveDeleteASession = (data) => ({
    type: Constants.RECEIVE_DELETE_A_SESSION,
    id: data.data.id
});


export function deleteASession(session_id = 0) {
    return (dispatch) => {
        return dispatch(deleteASessionAPI(session_id))
    }
}

export const fetchOneSessionFromWorkplan = (workplanActivity) => ({
    type: Constants.FETCH_ONE_SESSION_FROM_WORKPLAN,
    workplanActivity: workplanActivity
});