import React from 'react';
import { logout } from '../../action/user';
import { connect } from "react-redux";
import { fetchBranchList } from '../../action/branch';
import { fetchWorkplanActivityList } from '../../action/workplan';
import { fetchOneSessionFromWorkplan } from '../../action/session';
import { minuteFormat } from '../../utils/date';
import { toggleSelect } from '../../action/view';
import { pageChange, pageChangeWithNoStateChange } from '../../action/view';
import Slider from "react-slick";
import '../../sass/slick.min.css';
import * as Constants from '../../Constants';
import * as ENV from '../../env';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    logout,
    fetchBranchList,
    fetchWorkplanActivityList,
    toggleSelect,
    pageChange,
    pageChangeWithNoStateChange,
    fetchOneSessionFromWorkplan
};

class Activities extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: 0
        }
        this.props.fetchBranchList();
        this.props.fetchWorkplanActivityList();
    }

    handleClick(e) {
        this.props.logout()
    }

    desktopClick(e) {
        document.location = ENV.DESKTOP_APP + '?action=redirect&view=desktop&access_token=' + JSON.parse(localStorage.getItem('user')).access_token
    }

    handleBranchChange(e) {
        this.props.fetchWorkplanActivityList(e.currentTarget.getAttribute('data-id'));
        this.setState({
            active: parseInt(e.currentTarget.getAttribute('data-id'))
        })
    }


    onPageChange(e) {
        this.props.pageChange(e.currentTarget.getAttribute('data-page'));
        this.props.toggleSelect();
    }

    openTypePopup() {
        this.props.toggleSelect('popup');
    }

    onWorkplanActivityClick(e) {
        let workplanActivity = this.props.workplanView.workplanActivityList.filter(function (workplanActivity) {
            return workplanActivity.id === parseInt(e.currentTarget.getAttribute('data-id'));
        });
        this.props.fetchOneSessionFromWorkplan(workplanActivity[0]);
        this.props.pageChangeWithNoStateChange(Constants.ADD_DIRECT_SESSION)
    }


    render() {
        const PrevArrow = ({ className, onClick, ...props }) => (
            <div
                className={className}

                onClick={onClick}
                data-role="none">
                ←
            </div>
        )
        const NextArrow = ({ className, onClick, ...props }) => (
            <div
                className={className}

                onClick={onClick}
                data-role="none">
                →
            </div>
        )
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToScroll: 5,
            arrows: true,
            variableWidth: true,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />
        };

        const branchList = this.props.branchView.branchList
        const branches = branchList.map((branch, key) => {
            return (
                branch.workplan_count ?
                    <div className={"branch-item clickable " + (this.state.active === branch.id ? "active" : "")} key={key} data-id={branch.id} onClick={this.handleBranchChange.bind(this)}>
                        <div className="branch-name">{branch.name}</div>
                    </div> : "");
        });

        //test
        const user = JSON.parse(localStorage.getItem('user')).user;

        const workplanActivityList = this.props.workplanView.workplanActivityList;
        let workplanActivities = [];

        if (workplanActivityList) {
            workplanActivities = workplanActivityList.map((workplanActivity, key) => {
                return (
                    workplanActivity.workplan.deleted_at || workplanActivity.deleted_at ? "" :
                        <div className="goodnews upsert-one-element-div normal-box clickable" key={key} onClick={this.onWorkplanActivityClick.bind(this)} data-id={workplanActivity.id}>
                            <div className="list-div">
                                {workplanActivity.activity_type.image_url ? <div className="image-circle inline"><img className="img" src={workplanActivity.activity_type.image_url} alt={workplanActivity.activity_type.name} /></div>
                                    : <div className=" inline">
                                        <div className="user-logo ">
                                            {workplanActivity.activity_type.abbreviation}
                                        </div>
                                    </div>}
                                <div className="name inline">
                                    {workplanActivity.activity_type.name + ' | ' + workplanActivity.workplan.branch.name}
                                    <br />
                                    <span>{workplanActivity.age.name}<span className="dots-span"> . </span>{minuteFormat(workplanActivity.delivery_duration)}</span>
                                </div>
                                <div className="arrow-div inline float-right"><img src="/img/arrow.svg" alt="arrow" className="arrow" /></div>
                            </div>
                        </div>)
            })
        }

        return (
            <div>
                <div className="mobile-activities">
                    <p className="logout ab clickable" onClick={this.handleClick.bind(this)}>Log Out</p>
                    <p className="desktop ab clickable" onClick={this.desktopClick.bind(this)}>Desktop Link</p>
                    <h1> Hello  {user.first_name}!</h1>
                    <p>Here are your planned activties.</p>
                    <Slider {...settings}>
                        <div className={"branch-item clickable " + (this.state.active === 0 ? "active" : "")} data-id={0} onClick={this.handleBranchChange.bind(this)}>
                            <div className="branch-name">All</div>
                        </div>
                        {branches}
                    </Slider>
                    {workplanActivities.length > 0 ? workplanActivities : "No planned activity is found"}
                    <div className="plus-button clickable" onClick={this.openTypePopup.bind(this)}>+</div>
                    {this.props.root.overlay && this.props.root.view_type === 'popup' ?
                        <div className="type-popup">
                            <div className="white-blue-button clickable" data-page={Constants.ADD_DIRECT_SESSION} onClick={this.onPageChange.bind(this)}>Direct</div>
                            <div className="blue-white-button clickable" data-page={Constants.ADD_INDIRECT_SESSION} onClick={this.onPageChange.bind(this)}>Indirect</div>
                        </div>
                        : ""}
                </div>
                <div className='placeholder'></div>
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Activities);
