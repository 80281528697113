import * as Constants from '../Constants';
import * as ENV from '../env';

const root = (state = Constants.rootInitialState, action) => {
    switch (action.type) {
        case Constants.PAGE_CHANGE:
        case Constants.PAGE_CHANGE_WITH_NO_STATE_CHANGE:
            return {
                isFetching: state.isFetching,
                page: action.page,
                overlay: state.overlay
            }
        case Constants.FETCHING_START:
            return {
                isFetching: true,
                page: state.page,
                overlay: state.overlay
            }
        case Constants.FETCHING_END:
            return {
                isFetching: false,
                page: state.page,
                overlay: state.overlay
            }
        case Constants.TOGGLE_SELECT:
            return {
                isFetching: state.isFetching,
                page: state.page,
                overlay: !state.overlay,
                view_type: action.view_type,
                id: action.id,
                id2: action.id2
            }
        case Constants.RECEIVE_LOGIN:
            if (action.user.user.role_id >= Constants.GUEST) {
                document.location = ENV.DESKTOP_APP + '?action=redirect&view=desktop&access_token=' + action.user.access_token
            } else {
                localStorage.setItem('user', JSON.stringify(action.user));
            }
            return state;
        case Constants.RECEIVE_LOGOUT:
            localStorage.removeItem('user');
            window.location.reload();
            return state;
        default:
            return state
    }
}

export default root;