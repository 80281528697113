import * as Constants from '../Constants';

const surveyView = (state = Constants.surveyViewInitialState, action) => {
    switch (action.type) {
        case Constants.RECEIVE_BRANCH_LIST:
            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: action.branchList,
                surveyList: state.surveyList,
                survey: state.survey,
                custom_attributes: state.custom_attributes
            }
        case Constants.RECEIVE_SURVEY_LIST:
            return {
                add_success: false,
                block: state.block,
                total_count: action.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                surveyList: action.surveyList,
                survey: state.survey,
                custom_attributes: state.custom_attributes
            }
        case Constants.UPDATE_CUSTOM_FIELD:
            if (action.view === Constants.SURVEY_VIEW) {
                let custom_attributes = state.custom_attributes;

                if(custom_attributes && custom_attributes.length > 0) {
                    let affectedAttribute = custom_attributes.find(x => x.id ===  parseInt(action.field));

                    if(affectedAttribute.answer && affectedAttribute.answer.length > 0 && affectedAttribute.attribute_type_id === 1) {
                        if(affectedAttribute.answer.includes(parseInt(action.data))) {
                            affectedAttribute.answer = affectedAttribute.answer.filter((item) => item !== parseInt(action.data));
                        } else {
                            affectedAttribute.answer.push(parseInt(action.data));
                        }
                    } else {
                        if(affectedAttribute.attribute_type_id === 4) {
                            affectedAttribute.answer = [action.data];
                        } else {
                            affectedAttribute.answer = [parseInt(action.data)];
                        }
                    }
                }

                return {
                    add_success: false,
                    block: state.block,
                    total_count: state.total_count,
                    hover_id: state.hover_id,
                    branchList: state.branchList,
                    surveyList: action.surveyList,
                    survey: state.survey,
                    custom_attributes: custom_attributes
                }
            }
            return state;
        case Constants.GET_SURVEY_CUSTOM_ATTRIBUTE:
            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                surveyList: action.surveyList,
                survey: state.survey,
                custom_attributes: action.custom_attributes
            }
        case Constants.FETCH_SURVEY:
            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                surveyList: state.surveyList,
                survey: action.survey,
                custom_attributes: state.custom_attributes
            }
        case Constants.HOVER_ENTER:
            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: parseInt(action.hover_id),
                branchList: state.branchList,
                surveyList: state.surveyList,
                survey: state.survey,
                custom_attributes: state.custom_attributes
            }
        case Constants.HOVER_LEAVE:
            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: 0,
                branchList: state.branchList,
                surveyList: state.surveyList,
                survey: state.survey,
                custom_attributes: state.custom_attributes
            }
        case Constants.SHOW_SERVER_ERROR_MSG:
            return {
                add_success: false,
                block: state.block,
                server_errors: action.errors,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                surveyList: state.surveyList,
                survey: state.survey,
                custom_attributes: state.custom_attributes
            }
        case Constants.PAGE_CHANGE:
            return {
                add_success: false,
                block: {
                    isBlocking: false,
                    blockOpen: false,
                    block_id: 0
                },
                message: "",
                total_count: 0,
                hover_id: 0,
                branchList: state.branchList,
                surveyList: state.surveyList,
                survey: {},
                custom_attributes: state.custom_attributes
            }
        case Constants.UPDATE_MODAL:
            return {
                add_success: false,
                block: action.block,
                server_errors: state.errors,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                surveyList: state.surveyList,
                survey: state.survey,
                custom_attributes: state.custom_attributes
            }
        case Constants.RECEIVE_CREATE_SURVEY:
            return {
                add_success: true,
                block: state.block,
                message: action.message,
                total_count: state.total_count,
                hover_id: action.survey.id,
                branchList: state.branchList,
                surveyList: state.surveyList,
                survey: action.survey,
                custom_attributes: state.custom_attributes
            }
        case Constants.RECEIVE_DELETE_A_SURVEY:
            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: state.survey.id,
                branchList: state.branchList,
                surveyList: state.surveyList,
                survey: {},
                custom_attributes: state.custom_attributes
            }
        default:
            return state;
    }
}

export default surveyView;