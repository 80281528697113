import axios from 'axios';
import * as Constants from '../Constants';
import { fetchingStart } from './view';
import { showInlineErrorMsg, showServerErrorMsg } from './common';

export const requestActivityList = () => ({ type: Constants.REQUEST_ACTIVITY_LIST });
export const receiveActivityList = (data) => ({
    type: Constants.RECEIVE_ACTIVITY_LIST,
    activityList: data.data
});

function fetchActivityListAPI() {

    return dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        dispatch(fetchingStart())
        return axios.get(Constants.API + 'activity_type/list', axiosConfig)
            .then(response => response.data)
            .then(data => dispatch(receiveActivityList(data)))
            .catch(function (error) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            })
    }
}

export function fetchActivityList() {
    return (dispatch) => {
        return dispatch(fetchActivityListAPI())
    }
}

export const fetchAnActivity = (id) => ({
    type: Constants.FETCH_AN_ACTIVITY,
    id: id
});

function upsertActivityAPI(activity, action = 'update') {
    return dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        dispatch(fetchingStart())
        return axios.post(Constants.API + 'activity_type/' + action, activity, axiosConfig)
            .then(response => response.data)
            .then(data => dispatch(action === 'update' ? receiveUpdateActivity(data) : receiveCreateActivity(data)))
            .then(action === 'update' ? alert('Successfully Updated') : '')
            .catch(function (error) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
                console.log(error.response.data);
                dispatch(showServerErrorMsg(error.response.data))
            })
    }
}

export const receiveUpdateActivity = (data) => ({
    type: Constants.RECEIVE_UPDATE_ACTIVITY,
    activity: data.data.activity_type,
    message: data.data.message
});

export const receiveCreateActivity = (data) => ({
    type: Constants.RECEIVE_CREATE_ACTIVITY,
    activity: data.data.activity_type,
    message: data.data.message
});

export function upsertActivity(activity, action = 'update') {
    let errors = {};
    if (!activity.name) {
        errors.name = 'Activity Name Is Required';
    }

    if (action === 'create') {
        if (!activity.type) {
            errors.type = 'Activity Type Is Required';
        }
    }

    if (activity.type && activity.type === Constants.TYPE_DIRECT) {
        if (!activity.image_url && !activity.image_base_64) {
            errors.image = 'Icon Is Required For Direct Activity';
        }
    }
    if (Object.keys(errors).length > 0) {
        return (dispatch) => {
            return dispatch(showInlineErrorMsg(errors))
        }
    } else {
        return (dispatch) => {
            return dispatch(upsertActivityAPI(activity, action))
        }
    }
}

function deleteAnActivityAPI(activity_id) {

    return dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        dispatch(fetchingStart())
        return axios.get(Constants.API + 'activity_type/delete/' + activity_id, axiosConfig)
            .then(response => response.data)
            .then(data => dispatch(receiveDeleteAnActivity(data)))
            .catch(function (error) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            })
    }
}

export const receiveDeleteAnActivity = (data) => ({
    type: Constants.RECEIVE_DELETE_AN_ACTIVITY,
    id: data.data.id
});


export function deleteAnActivity(activity_id = 0) {
    return (dispatch) => {
        return dispatch(deleteAnActivityAPI(activity_id))
    }
}