import axios from 'axios';
import { fetchingStart } from './view';
import * as EmailValidator from 'email-validator';
import * as Env from '../env';
import * as Constants from '../Constants';
import { showInlineErrorMsg, showServerErrorMsg } from './common';

export const receiveLogin = (data) => ({
    type: Constants.RECEIVE_LOGIN,
    user: data.data
});
export const receiveLogout = () => ({ type: Constants.RECEIVE_LOGOUT });
export const receiveUserList = (data) => ({
    type: Constants.RECEIVE_USER_LIST,
    userList: data.data.user,
    total_count: data.data.total_count
});

export const hoverEnter = (hover_id) => ({
    type: Constants.HOVER_ENTER,
    hover_id: hover_id
});
export const hoverLeave = () => ({ type: Constants.HOVER_LEAVE });

export const receiveAUser = (data) => ({
    type: Constants.RECEIVE_A_USER,
    user: data.data
});

export const receiveDeleteAUser = (data) => ({
    type: Constants.RECEIVE_DELETE_A_USER,
    user: data
});

export const changeFirstName = (first_name) => ({
    type: Constants.CHANGE_FIRST_NAME,
    first_name: first_name
});

export const changeLastName = (last_name) => ({
    type: Constants.CHANGE_LAST_NAME,
    last_name: last_name
});

export const changeEmail = (email) => ({
    type: Constants.CHANGE_EMAIL,
    email: email
});

export const changePhone = (phone) => ({
    type: Constants.CHANGE_PHONE,
    phone: phone
});

export const changePassword = (password) => ({
    type: Constants.CHANGE_PASSWORD,
    password: password
});

export const assignUserBranches = (branches) => ({
    type: Constants.ASSIGN_USER_BRANCHES,
    branches: branches
});

export const changeRole = (role_id) => ({
    type: Constants.CHANGE_ROLE,
    role_id: role_id
});

export const receiveUpdateUser = (data) => ({
    type: Constants.RECEIVE_UPDATE_USER,
    user: data.data.user,
    message: data.data.message
});
export const receiveCreateUser = (data) => ({
    type: Constants.RECEIVE_CREATE_USER,
    user: data.data.user,
    message: data.data.message
});

export const updateModal = (block) => ({
    type: Constants.UPDATE_MODAL,
    block: block
});

export const removeErrorMsg = () => ({ type: Constants.REMOVE_ERROR_MSG });

export const receiveResetPassword = (data) => ({
    type: Constants.RECEIVE_RESET_PASSWORD,
    user: data.data
});

export const receiveForgetPassword = (data) => ({
    type: Constants.RECEIVE_FORGET_PASSWORD,
    message: data.data.message
});

function loginAPI(data) {

    return dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return axios.post(Env.API + 'user/login', data, axiosConfig)
            .then(response => response.data)
            .then(data => dispatch(receiveLogin(data)))
            .catch(function(error) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
                dispatch(showServerErrorMsg(error.response.data))
            });
    }
}

export function login(data) {
    let errors = {};
    if (!EmailValidator.validate(data.email)) {
        errors.email = 'Invalid Email';
    }
    if (Object.keys(errors).length > 0) {
        return (dispatch) => {
            return dispatch(showInlineErrorMsg(errors))
        }
    } else {
        return (dispatch) => {
            return dispatch(loginAPI(data))
        }
    }
}

function logoutAPI(data) {

    return dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        console.log(axiosConfig);
        return axios.post(Env.API + 'user/logout', '', axiosConfig)
            .then(response => response.data)
            .then(data => dispatch(receiveLogout(data)))
            .catch(function(error) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            });
    }
}


export function logout() {
    return (dispatch) => {
        return dispatch(logoutAPI())
    }
}

function fetchUserListAPI(branch_id) {

    return dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        dispatch(fetchingStart())
        return axios.get(Env.API + 'user/list' + (branch_id ? '?branch_id=' + branch_id : ''), axiosConfig)
            .then(response => response.data)
            .then(data => dispatch(receiveUserList(data)))
            .catch(function(error) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            })
    }
}

export function fetchUserList(branch_id = 0) {
    return (dispatch) => {
        return dispatch(fetchUserListAPI(branch_id))

    }
}

function fetchAUserAPI(user_id) {

    return dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        dispatch(fetchingStart())
        return axios.get(Env.API + 'user/' + user_id, axiosConfig)
            .then(response => response.data)
            .then(data => dispatch(receiveAUser(data)))
            .catch(function(error) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            })
    }
}

export function fetchAUser(user_id = 0) {
    return (dispatch) => {
        return dispatch(fetchAUserAPI(user_id))

    }
}

function deleteAUserAPI(user_id) {

    return dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        dispatch(fetchingStart())
        return axios.get(Env.API + 'user/delete/' + user_id, axiosConfig)
            .then(response => response.data)
            .then(data => dispatch(receiveDeleteAUser(data)))
            .catch(function(error) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            })
    }
}

export function deleteAUser(user_id = 0) {
    return (dispatch) => {
        return dispatch(deleteAUserAPI(user_id))
    }
}

function updateUserAPI(user) {

    return dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        dispatch(fetchingStart())
        return axios.post(Env.API + 'user/edit', user, axiosConfig)
            .then(response => response.data)
            .then(data => dispatch(receiveUpdateUser(data)))
            .then(alert('Successfully Updated'))
            .catch(function(error) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
                dispatch(showServerErrorMsg(error.response.data))
            })
    }
}

export function updateUser(user) {
    let errors = {};
    if (!EmailValidator.validate(user.email)) {
        errors.email = 'Invalid Email';
    }
    if (!user.first_name) {
        errors.first_name = 'First Name Is Required';
    }

    if (!user.last_name) {
        errors.last_name = 'Last Name Is Required';
    }

    if (!user.role_id) {
        errors.role_id = 'Role Is Required';
    }

    if (Object.keys(errors).length > 0) {
        return (dispatch) => {
            return dispatch(showInlineErrorMsg(errors))
        }
    } else {
        return (dispatch) => {
            return dispatch(updateUserAPI(user))
        }
    }
}

function createUserAPI(user) {

    return dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                // "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        dispatch(fetchingStart())
        return axios.post(Env.API + 'user/create', user, axiosConfig)
            .then(response => response.data)
            .then(data => dispatch(receiveCreateUser(data)))
            .catch(function(error) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
                console.log(error.response.data);
                dispatch(showServerErrorMsg(error.response.data))
            })
    }
}

export function createUser(user) {
    let errors = {};
    if (!EmailValidator.validate(user.email)) {
        errors.email = 'Invalid Email';
    }
    if (!user.first_name) {
        errors.first_name = 'First Name Is Required';
    }

    if (!user.last_name) {
        errors.last_name = 'Last Name Is Required';
    }

    if (!user.role_id) {
        errors.role_id = 'Role Is Required';
    }
    if (Object.keys(errors).length > 0) {
        return (dispatch) => {
            return dispatch(showInlineErrorMsg(errors))
        }
    } else {
        return (dispatch) => {
            return dispatch(createUserAPI(user))
        }
    }
}

export function signup(user) {
    let errors = {};
    if (!EmailValidator.validate(user.email)) {
        errors.email = 'Invalid Email';
    }
    if (!user.first_name) {
        errors.first_name = 'First Name Is Required';
    }

    if (!user.last_name) {
        errors.last_name = 'Last Name Is Required';
    }
    if (Object.keys(errors).length > 0) {
        return (dispatch) => {
            return dispatch(showInlineErrorMsg(errors))
        }
    } else {
        user.role_id = Constants.GUEST;
        return (dispatch) => {
            return dispatch(createUserAPI(user))
        }
    }
}

function setPasswordAPI(user) {

    return dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                // "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        dispatch(fetchingStart())
        return axios.post(Env.API + 'user/reset', user, axiosConfig)
            .then(response => response.data)
            .then(data => dispatch(receiveResetPassword(data)))
            .catch(function(error) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
                console.log(error.response.data);
                dispatch(showServerErrorMsg(error.response.data))
            })
    }
}

export function setPassword(user) {
    let errors = {};
    if (!EmailValidator.validate(user.email)) {
        errors.email = 'Invalid Email';
    }
    if (!user.password) {
        errors.password = 'Password Is Required';
    } else {
        if (user.password.length < 8) {
            errors.password = 'Password must at least 8 characters';
        }
    }

    if (!user.confirm_password) {
        errors.confirm_password = 'Confirm Password Is Required';
    } else {
        if (!user.password) {
            errors.confirm_password = 'Passwords are not match';
        } else {
            if (user.confirm_password !== user.password) {
                errors.confirm_password = 'Passwords are not match';
            }
        }
    }

    if (Object.keys(errors).length > 0) {
        return (dispatch) => {
            return dispatch(showInlineErrorMsg(errors))
        }
    } else {
        user.role_id = Constants.GUEST;
        return (dispatch) => {
            return dispatch(setPasswordAPI(user))
        }
    }
}

function forgetPasswordAPI(user) {

    return dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                // "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        dispatch(fetchingStart())
        return axios.post(Env.API + 'user/forget', user, axiosConfig)
            .then(response => response.data)
            .then(data => dispatch(receiveForgetPassword(data)))
            .catch(function(error) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
                console.log(error.response.data);
                dispatch(showServerErrorMsg(error.response.data))
            })
    }
}

export function forgetPassword(user) {
    let errors = {};
    if (!EmailValidator.validate(user.email)) {
        errors.email = 'Invalid Email';
    }

    if (Object.keys(errors).length > 0) {
        return (dispatch) => {
            return dispatch(showInlineErrorMsg(errors))
        }
    } else {
        user.role_id = Constants.GUEST;
        return (dispatch) => {
            return dispatch(forgetPasswordAPI(user))
        }
    }
}