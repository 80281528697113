import React from 'react';
import { connect } from "react-redux";
import Login from './view/login/Login'
import ResetPassword from './view/login/ResetPassword'
import ForgetPassword from './view/login/ForgetPassword'
import Activities from './view/activity/Activities'
import AddSession from './view/activity/AddSession'
import Surveys from './view/survey/Surveys'
import GoodNews from './view/goodnews/GoodNews'
import Menu from './view/Menu'
import * as Constants from './Constants';
import './sass/main.scss';
import queryString from 'query-string';


function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {

};


class App extends React.Component {

  constructor(props) {
    super(props);
    let url = window.location.href;
    let params = queryString.parse(url);
    this.state = {
      page: params.token ? Constants.RESET_PASSWORD : Constants.LOGIN,
    }
  }

  handleRegisterClick() {
    this.setState({
      page: Constants.SIGNUP
    })
  }

  handleSignInClick() {
    this.setState({
      page: Constants.LOGIN
    })
  }

  handleForgetPasswordClick() {
    this.setState({
      page: Constants.FORGET_PASSWORD
    })
  }


  render() {
    return (
      <div>
        <div className={this.props.root.overlay ? "overlay active" : "overlay"}></div>
        {localStorage.getItem('user') ?
          <div className="Main" >
            <div className="App" >
              {this.props.root.page === Constants.ACTIVITIES ? < Activities> </Activities> : ""}
              {this.props.root.page === Constants.ADD_DIRECT_SESSION ? < AddSession type={Constants.TYPE_DIRECT}> </AddSession> : ""}
              {this.props.root.page === Constants.ADD_INDIRECT_SESSION ? < AddSession type={Constants.TYPE_INDIRECT}> </AddSession> : ""}
              {this.props.root.page === Constants.SURVEYS ? < Surveys> </Surveys> : ""}
              {this.props.root.page === Constants.GOOD_NEWS ? < GoodNews> </GoodNews> : ""}
            </div>
            <Menu></Menu>
          </div>
          :
          this.state.page === Constants.LOGIN ? <Login onRegisterClick={this.handleRegisterClick.bind(this)} onForgetPasswordClick={this.handleForgetPasswordClick.bind(this)}></Login> :
            this.state.page === Constants.FORGET_PASSWORD ? <ForgetPassword onSignInClick={this.handleSignInClick.bind(this)}></ForgetPassword> :
              this.state.page === Constants.RESET_PASSWORD ? < ResetPassword> </ResetPassword> : ""}
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);


