import React from 'react';
import { connect } from "react-redux";
import * as Constants from '../../Constants';
import { pageChangeWithNoStateChange, pageChange } from '../../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageChange,
    pageChangeWithNoStateChange
};
class AddSuccess extends React.Component {
    returnToSessiones(e){
        e.preventDefault();
        this.props.pageChange(Constants.ACTIVITIES);
    }


    render() {
        return (
            <div className="add-user-div normal-box float-left">
                <div className="add-user normal-margin center">
                    <img alt="Pistive Vote" src="/img/positive-vote.svg" className="postive-vote"></img>
                    <p>{this.props.sessionView.message ? this.props.sessionView.message: ""}</p>
                    <br/>
                    <h1 className="clickable" onClick={this.returnToSessiones.bind(this)}><img src="/img/arrow-left-red.svg" alt="Arrow Left" className="arrow"></img>Return to Activites</h1>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSuccess);
