import axios from 'axios';
import { fetchingStart, toggleSelect } from './view';
import * as Constants from '../Constants';
import * as Env from '../env';
import { fetchBranchList } from './branch';
import { showInlineErrorMsg, showServerErrorMsg } from './common';

export const receiveGoodNewsList = (data) => ({
    type: Constants.RECEIVE_GOOD_NEWS_LIST,
    goodNewsList: data.data.news,
    total_count: data.data.total_count,
    query_count: data.data.query_count
});

export const getCustomAttribute = (attributes) => ({
    type: Constants.GET_GOODNEWS_CUSTOM_ATTRIBUTE,
    attributes: attributes
})

export function getCustomAttributeGoodNews(branch_id, attached_to) {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        try {
            // dispatch(fetchingStart())
            let response = await axios.get(Constants.API + 'attribute/get?attached_to=' + attached_to + '&branches=' + branch_id, axiosConfig)
            const data = response.data.data;
            dispatch(getCustomAttribute(data.attributes));
        } catch (error) {
            if (error.response.data.error.code === 1) {
                localStorage.removeItem('user');
                window.location.reload();
            }
            dispatch(showServerErrorMsg(error.response.data))

        }
    }
}
export const receiveGoodNewsListBranch = (data) => ({
    type: Constants.RECEIVE_GOOD_NEWS_LIST_BRANCH,
    goodNewsList: data.data.news,
    total_count: data.data.total_count
});

// fetch overview data for dashboard
function fetchGoodNewsListAPI(user_id = null, branch_id = 0, str = null, start = 0, count = Constants.MAX_COUNT) {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        // dispatch(fetchingStart())
        if (branch_id === "-1") {
            user_id = JSON.parse(localStorage.getItem('user')).user.id;
            branch_id = '';
        }
        try {
            let response = await axios.get(Env.API + 'good_news/list?start=' + start + '&count=' + count + (branch_id ? '&branch_id=' + branch_id : '') + (str ? '&str=' + str : '') + (user_id ? '&user_id=' + user_id : ''), axiosConfig);

            const data = response.data;

            if(start === 0) {
                return dispatch(receiveGoodNewsListBranch(data));
            } else {
                return dispatch(receiveGoodNewsList(data));
            }
            
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error !== undefined) {
                    if (error.response.data.error.code === 1) {
                        localStorage.removeItem('user');
                        window.location.reload();
                    }
                }
            }
        }
    }
}

export function fetchGoodNewsList(user_id = null, branch_id = 0, str = null, start = 0, count = Constants.MAX_COUNT) {
    return (dispatch) => {
        return dispatch(fetchGoodNewsListAPI(user_id, branch_id, str, start, count))
    }
}

function deleteAGoodNewsAPI(good_news_id, branch_id, page, str) {

    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        // dispatch(fetchingStart())
        try {
            const response = await axios.get(Constants.API + 'good_news/delete/' + good_news_id, axiosConfig);
            const data = response.data;
            dispatch(receiveDeleteAGoodNews(data));
            dispatch(fetchGoodNewsList(null, branch_id, str, 0, page * Constants.MAX_COUNT));
            dispatch(fetchBranchList());
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
        }
    }
}

export const receiveDeleteAGoodNews = (data) => ({
    type: Constants.RECEIVE_DELETE_A_GOOD_NEWS,
    id: data.data.id
});


export function deleteAGoodNews(good_news_id = 0, branch_id = 0, page = 1, str = null) {
    return (dispatch) => {
        return dispatch(deleteAGoodNewsAPI(good_news_id, branch_id, page, str))
    }
}
export const selectGoodNewsBranch = (branch) => ({
    type: Constants.SELECT_GOOD_NEWS_BRANCH,
    branch: branch
});

function upsertGoodNewsAPI(goodNews, action = 'update') {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        dispatch(fetchingStart())
        try {
            const response = await axios.post(Constants.API + 'good_news/' + action, goodNews, axiosConfig)
            const data = response.data;
            dispatch(action === 'update' ? receiveUpdateGoodNews(data) : receiveCreateGoodNews(data));
            dispatch(fetchGoodNewsListAPI());
            dispatch(fetchBranchList());
            dispatch(toggleSelect());
            alert(data.data.message);
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
                dispatch(showServerErrorMsg(error.response.data))
            }
        }
    }
}

export const receiveUpdateGoodNews = (data) => ({
    type: Constants.RECEIVE_UPDATE_GOOD_NEWS,
    goodNews: data.data.news,
    message: data.data.message
});

export const receiveCreateGoodNews = (data) => ({
    type: Constants.RECEIVE_CREATE_GOOD_NEWS,
    goodNews: data.data.news,
    message: data.data.message
});

export function upsertGoodNews(goodNews, action = 'update') {
    let errors = {};
    if (!goodNews.title) {
        errors.title = 'Title Is Required';
    }

    if (!goodNews.description) {
        errors.description = 'Description Is Required';
    }

    if (!goodNews.branch) {
        errors.branch = 'Branch Is Required';
    }

    if (!goodNews.image_url && !goodNews.image_base_64) {
        errors.image = 'Photo Is Required';
    }

    if(goodNews.custom_attributes && goodNews.custom_attributes.length === 0) {
        goodNews.attribute_goodnews = goodNews.custom_attributes;
    }

    if (Object.keys(errors).length > 0) {
        return (dispatch) => {
            return dispatch(showInlineErrorMsg(errors))
        }
    } else {
        return (dispatch) => {
            return dispatch(upsertGoodNewsAPI(goodNews, action))
        }
    }
}


export const fetchAGoodNews = (id) => ({
    type: Constants.FETCH_A_GOOD_NEWS,
    id: id
});

export async function fetchGoodNewsPDF(id, str) {
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
        }
    };
    try {

        const response = await axios.get(Constants.API + 'report/newsPdf?branch_id=' + id + '&str=' + str, axiosConfig)
        const data = response.data;
        return data
    } catch (error) {
        if (error.response !== undefined) {
            if (error.response.data.error.code === 1) {
                localStorage.removeItem('user');
                window.location.reload();
            }
        }
    }
}