import * as Constants from '../Constants';
import * as Env from '../env';

const loginView = (state = {}, action) => {
    switch (action.type) {
        case Constants.SHOW_INLINE_ERROR_MSG:
            return {
                errors: action.errors,
            }
        case Constants.SHOW_SERVER_ERROR_MSG:
            return {
                server_errors: action.errors
            }
        case Constants.RECEIVE_CREATE_USER:
            return {
                message: action.message,
            }
        case Constants.RECEIVE_RESET_PASSWORD:
            localStorage.setItem('user', JSON.stringify(action.user));
            window.location.href = Env.APP;
            return {}
        case Constants.RECEIVE_FORGET_PASSWORD:
            return {
                message: action.message,
            }
        default:
            return {}
    }
}

export default loginView;