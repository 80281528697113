import React from 'react';
import { connect } from "react-redux";
import { forgetPassword, removeErrorMsg } from '../../action/user';
import * as Env from '../../env';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  forgetPassword,
  removeErrorMsg
};

class Signup extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      email: ''
    }
  }
  emailChange(e) {
    this.setState({
      email: e.currentTarget.value,
    });
    this.props.removeErrorMsg();
  }

  handleClick(e) {
    console.log(this.state);
    this.props.forgetPassword(this.state);
  }

  onSignInClick() {
    window.location.href = Env.APP;
  }

  render() {
    const message = this.props.loginView.message; 
    const errors = this.props.loginView.errors;
    const server_errors = this.props.loginView.server_errors;
    const server_error_str = server_errors ? server_errors.map((error, key) =>
      <div className="error-img-div" key={key}>
        <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{error}
      </div>
    ) : "";
    return (
      <div className="ForgetPassword login-system">
        <img src="/img/arrow-left.svg" alt="Arrow Left" className="arrow-left big clickable" onClick={this.onSignInClick.bind(this)}/>
        <div className="white-background">
        
          {server_errors ?
            <div className="error-div">
              {server_error_str}
            </div> : ""}
          
            { message ? 
            <div className="sign-in-box">
              <h1>Request Recived</h1>
              <img alt="Pistive Vote" src="/img/positive-vote.svg" className="postive-vote"></img>
              <p className="message">Thanks! An email has been sent to you, follow the instructions.</p>
            </div>
            :
            <div className="sign-in-box">
              <h1>Forget Password</h1>
              <h5>Please enter your email</h5>
              <input name="email" type="email" placeholder="Email Address" onChange={this.emailChange.bind(this)}></input>
              {errors && errors.email ?
                <div className="error-img-div float-right">
                  <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.email}
                </div> : ""}
              <div className="button-div">
                <button onClick={this.handleClick.bind(this)}>Send Request</button>
              </div>
            </div>}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup);
