import { combineReducers } from 'redux'
import root from './root'
import loginView from './loginView'
import branchView from './branchView'
import goodNewsView from './goodNewsView'
import surveyView from './surveyView'
import activityView from './activityView'
import workplanView from './workplanView'
import sessionView from './sessionView'
import attributeView from './attributeView'

export default combineReducers({
    root,
    loginView,
    goodNewsView,
    branchView,
    surveyView,
    activityView,
    workplanView,
    sessionView,
    attributeView
})