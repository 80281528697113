import React from 'react';
import ReactDOM from 'react-dom';
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware } from 'redux';
import reducer from './reducer/index';
import App from './App';
import { Provider } from "react-redux";
import queryString from 'query-string';
import * as ENV from './env';
let url = window.location.href;
let params = queryString.parse(url);
if (params.view === 'logout') {
    localStorage.removeItem('user');
    document.location = ENV.APP
}

const loggerMiddleware = createLogger()

const store = createStore(
    reducer,
    applyMiddleware(
        thunkMiddleware, // lets us dispatch() functions
        // loggerMiddleware // neat middleware that logs actions
    )
);


ReactDOM.render(<Provider store={store} >< App /></Provider>, document.getElementById('root'));