import * as Constants from '../Constants';

const sessionView = (state = Constants.workplanViewInitialState, action) => {
    let workplan = {};
    let workplan_activity = [];
    let update_workplan_activity_inarray = [];
    let update_workplan_activity = [];
    let return_workplan_activity = [];
    switch (action.type) {
        case Constants.RECEIVE_WORKPLAN_LIST:
            return {
                workplanList: action.workplanList,
                workplan: state.workplan,
                total_count: action.total_count,
                workplanActivityList: state.workplanActivityList
            }
        case Constants.FETCH_A_WORKPLAN:
            let returnWorkplan = {};
            for (var i = 0; i < state.workplanList.length; i++) {
                workplan = state.workplanList[i];
                if (workplan.id === parseInt(action.id)) {
                    returnWorkplan = workplan;
                    break;
                }
            }
            return {
                workplanList: state.workplanList,
                workplan: {
                    ...returnWorkplan
                },
                total_count: state.total_count,
                workplanActivityList: state.workplanActivityList
            }
        case Constants.SELECT_WORKPLAN_BRANCH:
            return {
                workplanList: state.workplanList,
                workplan: {
                    ...state.workplan,
                    branch: {
                        id: action.branch.id,
                        name: action.branch.name
                    },
                    branch_id: action.branch.id
                },
                total_count: state.total_count,
                workplanActivityList: state.workplanActivityList
            }
        case Constants.SELECT_WORKPLAN_ACTIVITY:
            let activities = action.activities
            let return_work_plan_activity = [];
            if (state.workplan.work_plan_activity) {
                for (var i3 = 0; i3 < state.workplan.work_plan_activity.length; i3++) {
                    let work_plan_activity = state.workplan.work_plan_activity[i3];
                    let isFound = false;
                    for (var i4 = 0; i4 < action.activities.length; i4++) {
                        let new_activity = action.activities[i4];
                        if (work_plan_activity.activity_type_id === new_activity.id) {
                            work_plan_activity.deleted = false
                            return_work_plan_activity.push(work_plan_activity);
                            isFound = true;
                            break;
                        }
                    }
                    if (!isFound) {
                        work_plan_activity.deleted = true
                        return_work_plan_activity.push(work_plan_activity);
                    }
                }
            }

            let pluck_return_work_plan_activity = return_work_plan_activity.map(a => a.activity_type_id);
            let pluck_new_activity = activities.map(a => a.id);
            let new_array = pluck_new_activity.filter(function(el) {
                return pluck_return_work_plan_activity.indexOf(el) < 0;
            });

            for (var i5 = 0; i5 < new_array.length; i5++) {
                let id = new_array[i5];
                let activity = activities.filter(function(el) {
                    return el.id === id;
                });
                return_work_plan_activity.push({
                    id: 0,
                    activity_type_id: activity[0].id,
                    activity_type: activity[0]
                });
            }

            return {
                workplanList: state.workplanList,
                workplan: {
                    ...state.workplan,
                    work_plan_activity: return_work_plan_activity
                },
                total_count: state.total_count,
                workplanActivityList: state.workplanActivityList
            }

        case Constants.SELECT_WORKPLAN_USER:
            workplan_activity = state.workplan.work_plan_activity

            update_workplan_activity_inarray = workplan_activity.filter(function(el) {
                return el.activity_type_id === action.id;
            });

            update_workplan_activity = {
                ...update_workplan_activity_inarray[0]
            }

            update_workplan_activity.assign_officer = action.user.id;
            update_workplan_activity.user = action.user;

            return_workplan_activity = []
            for (var i6 = 0; i6 < workplan_activity.length; i6++) {
                if (workplan_activity[i6].activity_type_id === action.id) {
                    return_workplan_activity.push(update_workplan_activity)
                } else {
                    return_workplan_activity.push(workplan_activity[i6])
                }
            }
            return {
                workplanList: state.workplanList,
                workplan: {
                    ...state.workplan,
                    work_plan_activity: return_workplan_activity
                },
                total_count: state.total_count,
                workplanActivityList: state.workplanActivityList
            }

        case Constants.UPDATE_FIELD:
            if (action.view === Constants.WORKPLAN_VIEW) {
                workplan = {
                    ...state.workplan,
                }
                workplan[action.field] = action.data;
                return {
                    workplanList: state.workplanList,
                    workplan: workplan,
                    total_count: state.total_count,
                    workplanActivityList: state.workplanActivityList
                }
            }
            return state
        case Constants.UPDATE_WORKPLAN_ACTIVITY_FIELD:

            workplan_activity = state.workplan.work_plan_activity

            update_workplan_activity_inarray = workplan_activity.filter(function(el) {
                return el.activity_type_id === action.id;
            });

            update_workplan_activity = {
                ...update_workplan_activity_inarray[0]
            }
            if (action.field === 'target_age') {
                update_workplan_activity[action.field] = parseInt(action.value) ? parseInt(action.value) : 6;
                update_workplan_activity.age = {
                    id: parseInt(action.value) ? parseInt(action.value) : 6,
                    name: Constants.AGE_ID[parseInt(action.value)]
                }
            } else if (action.field.includes('ins_')) {
                if (!update_workplan_activity.instructor) {
                    update_workplan_activity.instructor = {
                        ...Constants.INSTRUCTOR
                    }
                }
                update_workplan_activity.instructor[action.field.replace('ins_', '')] = action.value
            } else if (action.field.includes('ind_')) {
                if (!update_workplan_activity.indemnification) {
                    update_workplan_activity.indemnification = {
                        ...Constants.INSTRUCTOR
                    }
                }
                update_workplan_activity.indemnification[action.field.replace('ind_', '')] = action.value
            } else if (action.field === 'occurrence' || action.field === 'overhead_duration' || action.field === 'delivery_duration') {
                update_workplan_activity[action.field] = parseInt(action.value);
            } else {
                update_workplan_activity[action.field] = action.value;
            }

            return_workplan_activity = []
            for (var i7 = 0; i7 < workplan_activity.length; i7++) {
                if (workplan_activity[i7].activity_type_id === action.id) {
                    return_workplan_activity.push(update_workplan_activity)
                } else {
                    return_workplan_activity.push(workplan_activity[i7])
                }
            }
            return {
                workplanList: state.workplanList,
                workplan: {
                    ...state.workplan,
                    work_plan_activity: return_workplan_activity
                },
                total_count: state.total_count,
                workplanActivityList: state.workplanActivityList
            }

        case Constants.RECEIVE_UPDATE_WORKPLAN:
            for (var i2 = 0; i2 < state.workplanList.length; i2++) {
                workplan = state.workplanList[i2];
                if (workplan.id === parseInt(action.workplan.id)) {
                    state.workplanList[i2] = action.workplan
                    break;
                }
            }
            return {
                workplanList: state.workplanList,
                workplan: action.workplan,
                total_count: state.total_count,
                workplanActivityList: state.workplanActivityList
            }
            // case Constants.PAGE_CHANGE:
            //     return {
            //         sessionList: state.sessionList,
            //         session: {},
            //         total_count: state.total_count
            //     }
        case Constants.SHOW_INLINE_ERROR_MSG:
            return {
                workplanList: state.workplanList,
                workplan: state.workplan,
                errors: action.errors,
                total_count: state.total_count,
                workplanActivityList: state.workplanActivityList
            }

        case Constants.SHOW_SERVER_ERROR_MSG:
            return {
                workplanList: state.workplanList,
                workplan: state.workplan,
                server_errors: action.errors,
                total_count: state.total_count,
                workplanActivityList: state.workplanActivityList
            }
        case Constants.RECEIVE_CREATE_WORKPLAN:
            return {
                add_success: true,
                message: action.message,
                workplanList: state.workplanList,
                workplan: action.workplan,
                workplanActivityList: state.workplanActivityList
            }
        case Constants.RECEIVE_DELETE_A_WORKPLAN:
            return {
                workplanList: state.workplanList.filter(function(workplan) {
                    return workplan.id !== parseInt(action.id);
                }),
                workplan: {},
                total_count: state.total_count - 1,
                workplanActivityList: state.workplanActivityList
            }

        case Constants.RECEIVE_WORKPLAN_ACTIVITY_LIST:
            return {
                ...state,
                workplanActivityList: action.workplanActivityList
            }

        default:
            return state;
    }
}

export default sessionView;