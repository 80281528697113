import React from 'react';
import FormStep from './FormStep';
import AddSuccess from './AddSuccess';
import { connect } from "react-redux";
import * as Constants from '../../Constants';
import { pageChange } from '../../action/view';
import { updateModal, createSurvey, getCustomAttributeSurvey } from '../../action/survey';
import Modal from 'react-responsive-modal';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageChange,
    updateModal,
    createSurvey,
    getCustomAttributeSurvey
};

const initialState = {
    currentStep: 1,
    branch_id: null,
    activity_type_id: null,
    satisfaction: null,
    custom_attributes: []
}
class AddSurveyForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = initialState;

        this.handleUpdateForm = this.handleUpdateForm.bind(this);
        this.handleNavigation = this.handleNavigation.bind(this);
        this.handleLogAnother = this.handleLogAnother.bind(this);
    }

    onCloseBlockModal(e) {
        let block = this.props.surveyView.block;
        this.props.updateModal({
            blockOpen: false,
            block_id: block.block_id,
            isBlocking: block.isBlocking,
            type: block.type
        })
    }

    handleUpdateForm(type, id) {
        this.setState({ [type]: id }, () => {
            if(this.state.branch_id && this.state.activity_type_id) {
                console.log('update');
                this.props.getCustomAttributeSurvey(this.state.branch_id, this.state.activity_type_id, Constants.ATTACHED_TO_TEXT[2]);
                this.setState({
                    custom_attributes: this.props.surveyView.custom_attributes
                })
            }
        });
        
        this.props.updateModal({
            isBlocking: true,
            blockOpen: false,
            block_id: Constants.ADD_SURVEY,
            type: 'page'
        });
    }

    handleNavigation(stepType, step) {
        let currentStep = this.state.currentStep;
        let nextStep = currentStep;
        let maxStep = Constants.MAX_SURVEY_STEP;

        if(this.props.surveyView.custom_attributes.length <= 0) {
            maxStep = maxStep - 1;
        }

        switch(step) {
            case '+1':
                if (this.state[stepType] === '' || this.state[stepType] === null) {
                    alert('Please select a valid answer!');
                } else {
                    nextStep = currentStep + 1;

                    if (nextStep > maxStep) {
                        const date = new Date();
                        const user = JSON.parse(localStorage.getItem('user')).user;

                        // clean up data    
                        let submitData = {
                            branch_id: parseInt(this.state.branch_id),
                            activity_type_id: parseInt(this.state.activity_type_id),
                            satisfaction_level: parseInt(this.state.satisfaction),
                            custom_attributes: this.props.surveyView.custom_attributes,
                            created_by: parseInt(user.id),
                            created_at: date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + date.getDate() + 'T' + ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2) + ':' + ("0" + date.getSeconds()).slice(-2) + '+10:00',
                        };

                        this.props.updateModal({
                            isBlocking: false,
                            blockOpen: false,
                            block_id: 0
                        });

                        // submit
                        this.props.createSurvey(submitData);

                        //nextStep = 1;
                        this.setState(initialState);
                    }
                }
                break;
            
            case '-1':
                nextStep = currentStep - 1;

                if (nextStep <= 0) {
                    // change page
                    if (this.props.surveyView.block.isBlocking) {
                        this.props.updateModal({
                            isBlocking: true,
                            blockOpen: true,
                            block_id: Constants.ACTIVITIES,
                            type: 'page'
                        });

                        nextStep = currentStep;
                    } else {
                        this.props.pageChange(Constants.ACTIVITIES);
                    };
                }
                break        
            default:
                nextStep = currentStep;
                break;
        }

        this.setState({ currentStep: nextStep});
    }

    handleLogAnother() {
        this.setState({ currentStep: 1 });

        this.props.pageChange(Constants.SURVEYS);
    }

    onHandlePageChange() {
        let block = this.props.surveyView.block;

        this.props.pageChange(block.block_id);
    }

    render() {
        let block = this.props.surveyView.block;

        const addSurveyForm = () => {
            return (
            <div>
                <div className="title">Add Survey</div>
                <FormStep mainState={this.state} currentStep={this.state.currentStep} onHandleNavigation={this.handleNavigation} onHandleUpdateForm={this.handleUpdateForm} />
                <Modal open={block.blockOpen} onClose={this.onCloseBlockModal.bind(this)} center>
                    <div className="userModal">
                        <p>You have not finished submitting the survey</p>
                        <p>Are you sure you want to navigate away?</p>
                        <div className="center">
                            <button className="yesButton" onClick={this.onHandlePageChange.bind(this)}>Yes</button>
                            <button className="cancelButton" onClick={this.onCloseBlockModal.bind(this)}>Cancel</button>
                        </div>
                    </div>
                </Modal>
            </div>
            )
        }

        return (
            <div>
                {
                    this.props.surveyView.add_success ? <AddSuccess onLogAnother={this.handleLogAnother}></AddSuccess> : addSurveyForm()
                }
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSurveyForm);
