import React from 'react';
import { connect } from "react-redux";
import * as Constants from '../../Constants';
import { pageChange } from '../../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageChange
};
class AddSuccess extends React.Component {
    returnToSurveys(e) {
        e.preventDefault();
        this.props.pageChange(Constants.ACTIVITIES);
    }

    logAnother(e) {
        e.preventDefault();
        this.props.onLogAnother();
    }

    render() {
        return (
            <div className="stepContainer">
                <img src='img/confirmation.svg' className='confirmation-success' alt='success' />
                <div className="stepTitle success">The survey has been logged.</div>

                <button className="white-red-button logAnother" onClick={this.logAnother.bind(this)}>Log Another</button>
                <div className="returnLink"><button href="#" onClick={this.returnToSurveys.bind(this)}>← Return to Activities</button></div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSuccess);
