import React from 'react';

class SelectSurvey extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: this.props.currentSelected
        }

        this.handleOnClick = this.handleOnClick.bind(this);
    }


    handleOnClick(e) {
        this.setState({ selected: e.currentTarget.value });

        this.props.onHandleUpdateForm(this.props.type, e.currentTarget.value);
    }

    render() {
        let list = this.props.data;
        const re = /(.svg)$/;

        const items = list.map((item, key) => {
            let img = '';

            if (re.exec(item[1])) {
                img = <img alt={item[0]} src={'img/' + item[1]} className="imageSurvey"/>
            }

            let value = item[0];
            if (this.props.type === 'age') {
                value = key + 1;
            }
            
            return (
                <label className={'selectSurvey fill-' + list.length + ' ' + this.props.type} key={key}>
                    <div className="selectImage">{img}</div>
                    <div className="selectName">{item[0].replace("Minutes", "Mins")}</div>
                    <input type="radio" id={item[0]} name={this.props.type} value={value} onChange={this.handleOnClick} checked={this.props.currentSelected === value || parseInt(this.props.currentSelected) === value}/>
                    <span className="checkmark"></span>
                    <div className="borderCheck"/>
                </label>
            )
        });


        return (
            <div className="branchList">
                <div className="groupContainer surveyField">
                    {items}
                </div>
            </div>
        );
    }
}

export default SelectSurvey
