function diff_seconds(dt2, dt1) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    return Math.abs(Math.round(diff));
}

export function timeDifference(createdAt) {
    let options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit"
    };

    let timeCreated = new Date(createdAt);

    let timeDifference = diff_seconds(timeCreated, new Date());
    let timeDisplay = timeCreated.toLocaleString('en-au', options);

    if (timeDifference < 3600) {

        // check if the time is less than a minute
        if (timeDifference < 60) {
            timeDisplay = Math.floor(timeDifference) + ' seconds ago';
        } else {
            timeDisplay = Math.floor(timeDifference / 60) + ' minutes ago';
        }
    }

    return timeDisplay;
}

export function formatDeliveryDate(date) {
    let options = {
        month: "short",
        day: "numeric",
        year: "numeric",
        weekday: "long"
    };

    let timeCreated = new Date(date);
    let timeDisplay = timeCreated.toLocaleString('en-au', options);

    return timeDisplay;
}

export function formatDeliveryDateHourMinute(date) {
    let options = {
        hour: "numeric",
        minute: "numeric",
        hour12: false
    };

    let timeCreated = new Date(date);
    let timeDisplay = timeCreated.toLocaleString('en-au', options);

    return timeDisplay;
}

export function getPeriods(currentPeriod) {
    let yearQuarter = currentPeriod.split(' Q');
    let period = [currentPeriod];
    let year = yearQuarter[0];
    let quarter = yearQuarter[1];
    let int = 0;
    while (int < 3) {
        if (quarter === 4) {
            year++;
            quarter = 1
        } else {
            quarter++;
        }
        period.push(year + ' Q' + quarter)
        int++;
    }
    return period;
}

export function hourFormat(hours) {
    let hour_count = 0;

    while (hours >= 1) {
        hour_count++;
        hours--;
    }

    let hourStr = "00";
    let minStr = "00";
    if (hour_count) {
        hourStr = hour_count.toFixed(0) + (hour_count > 1 ? ' hours' : ' hour');
    }
    
    if (hours) {
        minStr = (hours * 60).toFixed(0) + (hours * 60 > 1 ? ' mins' : ' min')
    }

    return hourStr + " : " + minStr

}

export function minuteFormat(minutes) {
    return minutes + " minutes "

}