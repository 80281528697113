import * as ENV from './env';

//Page View
export const LOGIN = "Login";
export const SIGNUP = "Signup";
export const RESET_PASSWORD = "Reset Password";
export const FORGET_PASSWORD = "Forget Password";
export const DASHBOARD = "Dashboard";
export const ADD_CHART = "Add Chart";
export const ACTIVITIES = "Activities";
export const ADD_ACTIVITY = "Add Activity";
export const SESSIONS = "Sessions";
export const ADD_DIRECT_SESSION = "Log Direct Session";
export const ADD_INDIRECT_SESSION = "Log Indirect Session";
export const WORKPLANS = "Programs";
export const ADD_WORKPLAN = "Add Program";
export const USERS = "Users";
export const ADD_USER = "Add User";
export const GOOD_NEWS = "Social Impact";
export const BRANCHES = "Clubs";
export const ADD_BRANCH = "Add Club";
export const SURVEYS = "Surveys";
export const ADD_SURVEY = "Add Survey";
export const ATTRIBUTES = "Custom Attributes";
export const ADD_ATTRIBUTE = "Add Attribute";

//Initial State
export const rootInitialState = {
    isFetching: false,
    page: ACTIVITIES,
    overlay: false
};

export const userViewInitialState = {
    add_success: false,
    block: {
        isBlocking: false,
        blockOpen: false,
        block_id: 0
    },
    total_count: 0,
    hover_id: 0,
    branchList: [],
    userList: [],
    user: {}
};

export const surveyViewInitialState = {
    add_success: false,
    block: {
        isBlocking: false,
        blockOpen: false,
        block_id: 0
    },
    total_count: 0,
    hover_id: 0,
    branchList: [],
    surveyList: [],
    survey: {},
    custom_attributes: []
};

export const attributeViewInitialState = {
    add_success: false,
    block: {
        isBlocking: false,
        blockOpen: false,
        block_id: 0
    },
    total_count: 0,
    hover_id: 0,
    branchList: [],
    attributeList: [],
    attribute: {}
};

export const branchViewInitialState = {
    branchList: [],
    branch: {}
};

export const chartViewInitialState = {
    chartList: []
}

export const activityViewInitialState = {
    activityList: [],
    activity: {}
};

export const sessionViewInitialState = {
    sessionList: [],
    session: {},
    total_count: 0
};

export const workplanViewInitialState = {
    workplanList: [],
    workplan: {},
    total_count: 0
};

export const goodNewsViewInitialState = {
    goodNewsList: [],
    goodNews: {
        // title: "",
        // description: "",
        // image_url: "",
        // branch_id: 0,
        // branch: {}
    },
    total_count: -1
};

export const overviewViewInitialState = {
    data: {
        data: []
    },
    timeSelected: 0,
    date: null
};

//Localhost
export const API = ENV.API;
export const APP = ENV.APP;

//User Role
export const SUPER_ADMIN = 1;
export const ADMIN = 2;
export const BRANCH_MANAGER = 3;
export const BRANCH_OFFICER = 4;
export const GUEST = 5;


//View
export const PAGE_CHANGE = "PAGE_CHANGE";
export const PAGE_CHANGE_WITH_NO_STATE_CHANGE = "PAGE_CHANGE_WITH_NO_STATE_CHANGE";
export const FETCHING_START = "FETCHING_START";
export const FETCHING_END = "FETCHING_END";
export const TOGGLE_SELECT = "TOGGLE_SELECT";
export const CLOSE_IMAGE = "CLOSE_IMAGE";
export const UPDATE_PREVIEW_FILE = "UPDATE_PREVIEW_FILE";
export const UPDATE_FIELD = "UPDATE_FIELD";
export const UPDATE_CUSTOM_FIELD = "UPDATE_CUSTOM_FIELD";
export const SIZE_FOR_PRINT = "SIZE_FOR_PRINT";

//Branch
export const REQUEST_BRANCH_LIST = "REQUEST_BRANCH_LIST";
export const RECEIVE_BRANCH_LIST = "RECEIVE_BRANCH_LIST";
export const FETCH_A_BRANCH = "FETCH_A_BRANCH";
export const BRANCH_NAME_CHANGE = "BRANCH_NAME_CHANGE";
export const ASSIGN_BRANCH_USERS = "ASSIGN_BRANCH_USERS";
export const RECEIVE_UPDATE_BRANCH = "RECEIVE_UPDATE_BRANCH";
export const RECEIVE_CREATE_BRANCH = "RECEIVE_CREATE_BRANCH";
export const RECEIVE_DELETE_A_BRANCH = "RECEIVE_DELETE_A_BRANCH";

//User
export const RECEIVE_LOGIN = "RECEIVE_LOGIN";
export const RECEIVE_LOGOUT = "RECEIVE_LOGOUT";
export const RECEIVE_USER_LIST = "RECEIVE_USER_LIST";
export const HOVER_ENTER = "HOVER_ENTER";
export const HOVER_LEAVE = "HOVER_LEAVE";
export const RECEIVE_ME = "RECEIVE_ME";

//Dashboard
export const RECEIVE_OVERVIEW_DATA = "RECEIVE_OVERVIEW_DATA";
export const RECEIVE_PERFORMANCE_DATA = "RECEIVE_PERFORMANCE_DATA";
export const RECEIVE_REPORT_CARD_DATA = "RECEIVE_REPORT_CARD_DATA";
export const RECEIVE_DAYS_SINCE_LAST_SESSION = "RECEIVE_DAYS_SINCE_LAST_SESSION";
export const RECEIVE_ALL_DASHBOARD = "RECEIVE_ALL_DASHBOARD";
export const RECEIVE_ALL_CHART = "RECEIVE_ALL_CHART";
export const RECEIVE_CREATE_CHART = "RECEIVE_CREATE_CHART";
export const CHANGE_DASHBOARD_BRANCH = "CHANGE_DASHBOARD_BRANCH";

//Surveys
export const SURVEY_VIEW = "SURVEY_VIEW";
export const RECEIVE_SURVEY_LIST = "RECEIVE_SURVEY_LIST";
export const FETCH_SURVEY = "FETCH_SURVEY";
export const RECEIVE_CREATE_SURVEY = "RECEIVE_CREATE_SURVEY";
export const RECEIVE_DELETE_A_SURVEY = "RECEIVE_DELETE_A_SURVEY";
export const GET_SURVEY_CUSTOM_ATTRIBUTE = "GET_SURVEY_CUSTOM_ATTRIBUTE";

//Attributes
export const RECEIVE_ATTRIBUTE_LIST = "RECEIVE_ATTRIBUTE_LIST";
export const FETCH_ATTRIBUTE = "FETCH_ATTRIBUTE";
export const RECEIVE_CREATE_ATTRIBUTE = "RECEIVE_CREATE_ATTRIBUTE";
export const RECEIVE_UPDATE_ATTRIBUTE = "RECEIVE_UPDATE_ATTRIBUTE";
export const RECEIVE_DELETE_A_ATTRIBUTE = "RECEIVE_DELETE_A_ATTRIBUTE";
export const ATTRIBUTE_CHANGE = "ATTRIBUTE_CHANGE";

//Activity
export const ACTIVITY_VIEW = "ACTIVITY_VIEW";
export const RECEIVE_ACTIVITY_LIST = "RECEIVE_ACTIVITY_LIST";
export const REQUEST_ACTIVITY_LIST = "REQUEST_ACTIVITY_LIST";
export const FETCH_AN_ACTIVITY = "FETCH_AN_ACTIVITY";
export const RECEIVE_UPDATE_ACTIVITY = "RECEIVE_UPDATE_ACTIVITY";
export const RECEIVE_CREATE_ACTIVITY = "RECEIVE_CREATE_ACTIVITY";
export const RECEIVE_DELETE_AN_ACTIVITY = "RECEIVE_DELETE_AN_ACTIVITY";
export const ATTRIBUTE_ACTIVITY_CHANGE = "ATTRIBUTE_ACTIVITY_CHANGE";
export const ATTRIBUTE_BRANCH_CHANGE = "ATTRIBUTE_BRANCH_CHANGE";

//Session
export const SESSION_VIEW = "SESSION_VIEW";
export const RECEIVE_SESSION_LIST = "RECEIVE_SESSION_LIST";
export const FETCH_A_SESSION = "FETCH_A_SESSION";
export const SELECT_SESSION_BRANCH = "SELECT_SESSION_BRANCH";
export const SELECT_SESSION_ACTIVITY = "SELECT_SESSION_ACTIVITY";
export const GET_SESSION_CUSTOM_ATTRIBUTE = "GET_SESSION_CUSTOM_ATTRIBUTE";
export const RECEIVE_UPDATE_SESSION = "RECEIVE_UPDATE_SESSION";
export const RECEIVE_CREATE_SESSION = "RECEIVE_CREATE_SESSION";
export const RECEIVE_DELETE_A_SESSION = "RECEIVE_DELETE_A_SESSION";

//Social Impact
export const GOOD_NEWS_VIEW = "GOOD_NEWS_VIEW";
export const RECEIVE_GOOD_NEWS_LIST = "RECEIVE_GOOD_NEWS_LIST";
export const RECEIVE_GOOD_NEWS_LIST_BRANCH = "RECEIVE_GOOD_NEWS_LIST_BRANCH";
export const FETCH_A_GOOD_NEWS = "FETCH_A_GOOD_NEWS";
export const SELECT_GOOD_NEWS_BRANCH = "SELECT_GOOD_NEWS_BRANCH";
export const RECEIVE_UPDATE_GOOD_NEWS = "RECEIVE_UPDATE_GOOD_NEWS";
export const RECEIVE_CREATE_GOOD_NEWS = "RECEIVE_CREATE_GOOD_NEWS";
export const RECEIVE_DELETE_A_GOOD_NEWS = "RECEIVE_DELETE_A_GOOD_NEWS";
export const FETCH_GOOD_NEWS_PDF = "FETCH_GOOD_NEWS_PDF";
export const GET_GOODNEWS_CUSTOM_ATTRIBUTE = "GET_GOODNEWS_CUSTOM_ATTRIBUTE";

//Workplan
export const WORKPLAN_VIEW = "WORKPLAN_VIEW";
export const RECEIVE_WORKPLAN_LIST = "RECEIVE_WORKPLAN_LIST";
export const RECEIVE_WORKPLAN_ACTIVITY_LIST = "RECEIVE_WORKPLAN_ACTIVITY_LIST";
export const FETCH_A_WORKPLAN = "FETCH_A_WORKPLAN";
export const SELECT_WORKPLAN_BRANCH = "SELECT_WORKPLAN_BRANCH";
export const SELECT_WORKPLAN_ACTIVITY = "SELECT_WORKPLAN_ACTIVITY";
export const SELECT_WORKPLAN_USER = "SELECT_WORKPLAN_USER";
export const RECEIVE_UPDATE_WORKPLAN = "RECEIVE_UPDATE_WORKPLAN";
export const RECEIVE_CREATE_WORKPLAN = "RECEIVE_CREATE_WORKPLAN";
export const RECEIVE_DELETE_A_WORKPLAN = "RECEIVE_DELETE_A_WORKPLAN";
export const UPDATE_WORKPLAN_ACTIVITY_FIELD = "UPDATE_WORKPLAN_ACTIVITY_FIELD";
export const WORKPLAN_MORE = 3;
export const FETCH_ONE_SESSION_FROM_WORKPLAN = "FETCH_ONE_SESSION_FROM_WORKPLAN";

//Data Type
export const DATA_TYPE_INT = "Int";
export const DATA_TYPE_STR = "Str";

//Activity Type
export const TYPE_DIRECT = "direct";
export const TYPE_INDIRECT = "indirect";

export const GENDER_ALL = "All";
export const GENDER_MALE = "Male";
export const GENDER_FEMALE = "Female";
export const GENDER_OTHER = 'Other';

export const PROGRAM_CATEGORY = [
    'Youth Development','Community Development','Community Engagement','Crime Prevention'
]

export const PROGRAM_TYPE = [
    'Ongoing','Fixed-Term','Event'
]

export const PARTICIPANTS_WITH_DISABILITY = [
    {
        label: 'Vision Impairment',
        value: 'vision_impairment'
    },
    {
        label: 'Deaf or hard of hearing',
        value: 'deaf'
    },
    {
        label: 'Mental health conditions',
        value: 'mental_health'
    },
    {
        label: 'Intellectual disability',
        value: 'intellectual_disability'
    },
    {
        label: 'Acquired brain injury',
        value: 'brain_injury'
    },
    {
        label: 'Autism spectrum disorder',
        value: 'autism'
    },
    {
        label: 'Physical disability',
        value: 'physical_disability'
    },
    {
        label: 'Other disability',
        value: 'other_disability'
    }
]

export const KPI = [
    'Exceeded','Yes','No'
]

export const FILTER_LIST = [
    'Last Four Weeks',
    'This Half Year',
    'Last Half Year',
    'This Year',
    'Last Year',
    'Custom Date Range'
];

export const REPORT_CARD = [{
        title: 'Minute'
    },
    {
        title: 'Ethnicity'
    },
    {
        title: 'Top Sports'
    },
    {
        title: 'Age Range'
    },
    {
        title: 'Gender'
    },
    {
        title: 'Participants'
    },
    {
        title: 'Would Return'
    }
];

export const OVERVIEW_LIST = [{
    name: 'Youth Development',
    colorStart: '#42AEE3',
    colorEnd: '#42AEE3',
    icon: 'goal.svg'
},
{
    name: 'Crime Prevention',
    colorStart: '#6500CB',
    colorEnd: '#6500CB',
    icon: 'shield.svg'
},
{
    name: 'Community Engagement',
    colorStart: '#FFDB00',
    colorEnd: '#FFDB00',
    icon: 'megaphone.svg'
},
{
    name: 'Community Development',
    colorStart: '#D36428',
    colorEnd: '#D36428',
    icon: 'handshake.svg'
},
{
    name: 'Attendance',
    colorStart: '#A9263D',
    colorEnd: '#892637',
    icon: 'friendship.svg'
},
{
    name: 'Social Outcomes & Key Results',
    colorStart: '#FFD800',
    colorEnd: '#EEA332',
    icon: 'volunteer.svg'
},
{
    name: 'Participant Satisfaction Level',
    colorStart: '#7ABB00',
    colorEnd: '#3A8900',
    icon: 'rate_5_happy.svg'
},
{
    name: 'Volunteers',
    colorStart: '#0074BD',
    colorEnd: '#004571',
    icon: 'group.svg'
}
];

export const SURVEY_RATE = [
    'na.svg',
    'rating_2_sad.svg',
    'rating_3_meh.svg',
    'rating_4_happy.svg',
    'rate_5_happy.svg',
    'rate_5_happy.svg'
]

export const ACTIVITY_TYPE = {
    1: '.svg',
    2: '.svg',
    3: '.svg',
    4: '.svg',
    5: '.svg'
}

export const ATTACHED_TO = {
    Session: 'workplans_inactive.svg',
    Survey: 'surevys_inactive.svg',
    'Social Impact': 'goodnews_inactive.svg'
}

export const ATTACHED_TO_TEXT = {
    1: 'session',
    2: 'survey',
    3: 'social impact'
}

export const ATTACHED_TO_ACTIVE = {
    Session: 'workplans_active.svg',
    Survey: 'surevys_active.svg',
    'Social Impact': 'goodnews_active.svg'
}

export const DISPLAY_BY = {
    'Bars': 'bars_inactive.svg',
    'Donuts & Bars': 'dounut_bars_inactive.svg',
}

export const DISPLAY_BY_TEXT = {
    1: 'Bars',
    2: 'Donuts & Bars'
}

export const DISPLAY_BY_ACTIVE = {
    'Bars': 'bars_active.svg',
    'Donuts & Bars': 'dounut_bars_active.svg'
}

export const ATTRIBUTE_TYPE = {
    'Multi Select': 'multi_inactive.svg',
    'Single Select': 'single_inactive.svg',
    'Yes or No': 'yes_no_inactive.svg',
    'Text Input': 'text_input_inactive.svg'
}

export const ATTRIBUTE_TYPE_ACTIVE = {
    'Multi Select': 'multi_active.svg',
    'Single Select': 'single_active.svg',
    'Yes or No': 'yes_no_active.svg',
    'Text Input': 'text_input_active.svg'
}

export const ATTRIBUTE_TYPE_DATABASE = {
    'Multi Select': 1,
    'Single Select': 2, 
    'Yes or No': 3,
    'Text Input': 4
}

export const ATTRIBUTE_TYPE_VIEW = {
    1 : 'Multi Select',
    2 : 'Single Select', 
    3 : 'Yes or No',
    4 : 'Text Input'
}

export const GENDER = {
    Female: 'female.svg',
    Male: 'male.svg'
}

export const ETHNICITY = {
    Aboriginal: 'aboriginal_flag.svg',
    'Torres Strait Islanders': 'torres_strait_islanders_flag.svg',
    ATSI: 'ATSI_flag.svg',
    Other: 'other_icon.svg'
}

export const AGE = {
    '0 - 4 Years': '0 - 4 Years',
    '5 - 9 Years': '5 - 9 Years',
    '10 - 14 Years': '10 - 14 Years',
    '15 - 19 Years': '15 - 19 Years',
    '20 - 24 Years': '20 - 24 Years',
    '24+': '24+'
}

export const AGE_ID = [
    '',
    '0 - 4 Years',
    '5 - 9 Years',
    '10 - 14 Years',
    '15 - 19 Years',
    '20 - 24 Years',
    '25 - 29 Years',
    '30+'
]

export const INSTRUCTOR = {
    name: "",
    contact_number: "",
    email: "",
    document: ""
}
export const ACTIVITY_AGE = [
    { id: 1, name: AGE_ID[1], count: 0, gender: GENDER_MALE },
    { id: 2, name: AGE_ID[2], count: 0, gender: GENDER_MALE },
    { id: 3, name: AGE_ID[3], count: 0, gender: GENDER_MALE },
    { id: 4, name: AGE_ID[4], count: 0, gender: GENDER_MALE },
    { id: 5, name: AGE_ID[5], count: 0, gender: GENDER_MALE },
    { id: 6, name: AGE_ID[6], count: 0, gender: GENDER_MALE },
    { id: 7, name: AGE_ID[7], count: 0, gender: GENDER_MALE },
    { id: 1, name: AGE_ID[1], count: 0, gender: GENDER_FEMALE },
    { id: 2, name: AGE_ID[2], count: 0, gender: GENDER_FEMALE },
    { id: 3, name: AGE_ID[3], count: 0, gender: GENDER_FEMALE },
    { id: 4, name: AGE_ID[4], count: 0, gender: GENDER_FEMALE },
    { id: 5, name: AGE_ID[5], count: 0, gender: GENDER_FEMALE },
    { id: 6, name: AGE_ID[6], count: 0, gender: GENDER_FEMALE },
    { id: 7, name: AGE_ID[7], count: 0, gender: GENDER_FEMALE },
    { id: 1, name: AGE_ID[1], count: 0, gender: GENDER_OTHER },
    { id: 2, name: AGE_ID[2], count: 0, gender: GENDER_OTHER },
    { id: 3, name: AGE_ID[3], count: 0, gender: GENDER_OTHER },
    { id: 4, name: AGE_ID[4], count: 0, gender: GENDER_OTHER },
    { id: 5, name: AGE_ID[5], count: 0, gender: GENDER_OTHER },
    { id: 6, name: AGE_ID[6], count: 0, gender: GENDER_OTHER },
    { id: 7, name: AGE_ID[7], count: 0, gender: GENDER_OTHER }
]

export const ACTIVITY_LEVEL = {
    0: '0',
    '1 - 15 Minutes': '1 - 15 Minutes',
    '15 - 30 Minutes': '15 - 30 Minutes',
    '30 - 60 Minutes': '30 - 60 Minutes',
    '60+': '60+'
}

export const SATISFACTION = {
    0: 'na.svg',
    1: 'rating_1_angry_colour.svg',
    2: 'rating_2_sad_colour.svg',
    3: 'rating_3_meh_colour.svg',
    4: 'rating_4_happy_colour.svg',
    5: 'rating_5_ecstatic_colour.svg'
}

export const TRUE_FALSE = {
    yes: 'yes.svg',
    no: 'no.svg'
}

export const ATTENDED_BEFORE = TRUE_FALSE;
export const IS_INTENT_TO_RETURN = TRUE_FALSE;

export const ARROWS = [
    '▲',
    '▼'
]

export const LOADING_ICON = '/img/loader.svg';

export const COLORS = ['#A9263D', '#FFD800', '#7ABB00', '#42AEE3', '#6500CB'];
export const PLACEHOLDER_BG = 'linear-gradient(120deg, #70706F, #7D7D7A)';

export const VERTICAL_WIDTH = 253;

export const MAX_LEVEL = 5;
export const RECEIVE_A_USER = "RECEIVE_A_USER";
export const RECEIVE_DELETE_A_USER = "RECEIVE_DELETE_A_USER";
export const CHANGE_FIRST_NAME = "CHANGE_FIRST_NAME";
export const CHANGE_LAST_NAME = "CHANGE_LAST_NAME";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const CHANGE_PHONE = "CHANGE_PHONE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const ASSIGN_USER_BRANCHES = "ASSIGN_USER_BRANCHES";
export const CHANGE_ROLE = "CHANGE_ROLE";
export const RECEIVE_UPDATE_USER = "RECEIVE_UPDATE_USER";
export const RECEIVE_CREATE_USER = "RECEIVE_CREATE_USER";
export const SHOW_INLINE_ERROR_MSG = "SHOW_INLINE_ERROR_MSG";
export const SHOW_SERVER_ERROR_MSG = "SHOW_SERVER_ERROR_MSG";
export const REMOVE_ERROR_MSG = "REMOVE_ERROR_MSG";
export const UPDATE_MODAL = "UPDATE_MODAL";
export const RECEIVE_RESET_PASSWORD = "RECEIVE_RESET_PASSWORD";
export const RECEIVE_FORGET_PASSWORD = "RECEIVE_FORGET_PASSWORD";
export const MAX_SURVEY_STEP = 4;
export const MAX_ATTRIBUTE_STEP = 5;
export const MAX_CHART_STEP = 3;
export const MAX_COUNT = 20;


export const PAGE_URL = {
    'dashboard': DASHBOARD,
    'activities': ACTIVITIES,
    'add-activities': ADD_ACTIVITY,
    'sessions': SESSIONS,
    'add-sessions-direct': ADD_DIRECT_SESSION,
    'add-sessions-indirect': ADD_INDIRECT_SESSION,
    'workplans': WORKPLANS,
    'add-workplans': ADD_WORKPLAN,
    'users': USERS,
    'add-users': ADD_USER,
    'good_news': GOOD_NEWS,
    'branches': BRANCHES,
    'add-branches': ADD_BRANCH,
    'surveys': SURVEYS,
    'add-surveys': ADD_SURVEY,
    'attributes': ATTRIBUTES,
    'add-attributes': ADD_ATTRIBUTE,
}