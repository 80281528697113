import React from 'react';
import { connect } from "react-redux";
import { upsertSession } from '../../action/session';
import { hoverEnter, hoverLeave } from '../../action/user';
import { toggleSelect, updateField, pageChange, updateAttributeField } from '../../action/view';
import { formatDeliveryDate, formatDeliveryDateHourMinute, minuteFormat } from '../../utils/date';
import * as Constants from '../../Constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  upsertSession,
  hoverEnter,
  hoverLeave,
  toggleSelect,
  updateField,
  pageChange,
  updateAttributeField
};
class LogSessionForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      type: this.props.type
    };
  }

  onDateChange(date, e) {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }

    this.setState({
      startDate: date,
      type: this.state.type
    });
    let delivery_date = this.props.sessionView.session.delivery_date
    if (delivery_date) {
      const hourMin = formatDeliveryDateHourMinute(delivery_date).split(":");
      date.setHours(hourMin[0]);
      date.setMinutes(hourMin[1]);
      date.setSeconds(0);
    } else {
      date.setHours(9); // default to 9AM
      date.setMinutes(0);
      date.setSeconds(0);
    }

    var moment = require('moment-timezone');
    var local = moment.tz(date.toISOString(), "Australia/Melbourne");
    this.props.updateField('delivery_date', Constants.SESSION_VIEW, local.format());
  }

  onHourChange(e) {
    let hour = e.currentTarget.value
    //let ampm = e.currentTarget.getAttribute('data-ampm')
    let delivery_date = this.props.sessionView.session.delivery_date
    var date = new Date(Date.now());
    if (delivery_date) {
      date = new Date(Date.parse(delivery_date));
      date.setHours(hour);
    } else {
      date.setHours(hour); // default to 9AM
      date.setMinutes(0);
      date.setSeconds(0);
    }
    // check if the current hour is PM or AM
    /*if(date.getHours() >= 0 && date.getHours() <= 12) {
      // this is in the morning
      if (ampm === 'PM') {
        date.setHours(hour + 12);
      } else {
        date.setHours(hour);
      }
    } else {
      // this is in the evening
      if(ampm === 'PM') {
        date.setHours(hour);
      } else {
        date.setHours(hour - 12);
      }
    }
    console.log(date);*/
    var moment = require('moment-timezone');
    var local = moment.tz(date.toISOString(), "Australia/Melbourne");
    this.props.updateField('delivery_date', Constants.SESSION_VIEW, local.format());

  }

  onMinuteChange(e) {
    let delivery_date = this.props.sessionView.session.delivery_date
    var date = new Date(delivery_date ? Date.parse(delivery_date) : Date.now());
    date.setSeconds(0);

    if (e.currentTarget.value >= 0 && e.currentTarget.value < 60) {
      date.setMinutes(e.currentTarget.value);
      var moment = require('moment-timezone');
      var local = moment.tz(date.toISOString(), "Australia/Melbourne");
      this.props.updateField('delivery_date', Constants.SESSION_VIEW, local.format());
    }
  }

  onAmPmChange(e) {
    let delivery_date = this.props.sessionView.session.delivery_date
    var date = new Date(delivery_date ? Date.parse(delivery_date) : Date.now());
    let ampm = e.currentTarget.value;
    if (delivery_date) {
      if (date.getHours() <= 12) {
        // in the morning
        if (ampm === 'AM') {
          date.setHours(date.getHours());
        } else {
          date.setHours(date.getHours() + 12);
        }
      } else {
        if (ampm === 'AM') {
          date.setHours(date.getHours() - 12);
        } else {
          date.setHours(date.getHours());
        }
      }

    }
    date.setMinutes(date.getMinutes() - date.getMinutes() % 15);
    var moment = require('moment-timezone');
    var local = moment.tz(date.toISOString(), "Australia/Melbourne");
    this.props.updateField('delivery_date', Constants.SESSION_VIEW, local.format());
  }

  selectDate() {
    document.getElementsByClassName('react-datepicker__input-container')[0].getElementsByTagName('input')[0].focus();
  }


  updateTextField(e) {
    e.preventDefault();
    this.props.updateField(e.currentTarget.getAttribute('data-field'), Constants.SESSION_VIEW, e.currentTarget.value);
  }

  updateNumberField(e) {
    e.preventDefault();
    let value = e.currentTarget.value;
    if (!value) {
      value = 0;
    }
    this.props.updateField(e.currentTarget.getAttribute('data-field'), Constants.SESSION_VIEW, parseFloat(value));
  }

  updateAgeField(e) {
    e.preventDefault();
    let gender = e.currentTarget.getAttribute('data-gender');
    let count = parseInt(e.currentTarget.value ? e.currentTarget.value : 0);
    let id = parseInt(e.currentTarget.getAttribute('data-id'));
    let age = {
      id: id,
      name: Constants.AGE_ID[id],
      gender: gender,
      count: count
    }
    let activity_session_age = this.props.sessionView.session.activity_session_age ? this.props.sessionView.session.activity_session_age : Constants.ACTIVITY_AGE;;
    let updateAges = [];
    for (let i = 0; i < activity_session_age.length; i++) {
      if (gender === activity_session_age[i].gender && id === activity_session_age[i].id) {
        updateAges.push(age);
      } else {
        updateAges.push(activity_session_age[i]);
      }
    }
    this.props.updateField('activity_session_age', Constants.SESSION_VIEW, updateAges);
  }

  handHoverEnter(e) {
    e.preventDefault();
    this.props.hoverEnter(e.currentTarget.getAttribute('data-id'));
  }

  handHoverLeave(e) {
    e.preventDefault();
    this.props.hoverLeave();
  }

  toggleSelect(e) {
    e.preventDefault();
    this.props.toggleSelect(e.currentTarget.getAttribute('data-id'));
  }

  upsertSession(e) {
    e.preventDefault();
    this.props.upsertSession(this.props.sessionView.session, 'create');
  }
  updateSelectAttribute(e) {
    e.preventDefault();
    let field = e.currentTarget.getAttribute('data-field');
    let data = e.currentTarget.getAttribute('data-id');

    if (data) {
      this.props.updateAttributeField(field, Constants.SESSION_VIEW, data);
    } else {
      this.props.updateAttributeField(field, Constants.SESSION_VIEW, e.currentTarget.value);
    }
  }
  updateSelectField(e) {
    e.preventDefault();
    let field = e.currentTarget.getAttribute('data-field');
    let data = e.currentTarget.getAttribute('data-id');

    if (data) {
      this.props.updateField(field, Constants.SESSION_VIEW, data);
    } else {
      this.props.updateField(field, Constants.SESSION_VIEW, e.currentTarget.value);
    }
  }
  onBackClick() {
    this.props.pageChange(Constants.ACTIVITIES)
  }

  renderSwitch(attribute) {
    switch (attribute.attribute_type_id) {
      case 1: // multi select
        return (
          <div className="role attributes">
            {
              attribute.attribute_options.map((option, key) => {
                if (!option.archived) {
                  return (
                    <div className={"role-div " + (attribute.answer && attribute.answer.includes(option.id) ? 'active' : '')} key={key} onClick={this.updateSelectAttribute.bind(this)} data-id={option.id} data-field={attribute.id} data-type="Int">{option.option}</div>
                  )
                }
                return <></>
              })
            }
          </div>
        );
      case 2: // single select
        return (
          <div className="role attributes">
            {
              attribute.attribute_options.map((option, key) => {
                return (
                  <div className={"role-div " + (attribute.answer && attribute.answer.includes(option.id) ? 'active' : '')} key={key} onClick={this.updateSelectAttribute.bind(this)} data-id={option.id} data-field={attribute.id} data-type="Int">{option.option}</div>
                )
              })
            }
          </div>
        );
      case 3: // yes no question
        return (
          <div className="role attributes">
            <div className={"role-div " + (attribute.answer && attribute.answer.includes(1) ? 'active' : '')} data-id="1" onClick={this.updateSelectAttribute.bind(this)} data-field={attribute.id} data-type="Int">Yes</div>
            <div className={"role-div " + (attribute.answer && attribute.answer.includes(0) ? 'active' : '')} data-id="0" onClick={this.updateSelectAttribute.bind(this)} data-field={attribute.id} data-type="Int">No</div>
          </div>
        );
      case 4:
        return (
          <input className="narrow" data-field={attribute.id}
            value={attribute.answer ? attribute.answer[0] : ''} onChange={this.updateSelectAttribute.bind(this)}
            data-step={this.props.type === Constants.TYPE_DIRECT ? 4 : 1}></input>
        );
      default:
        return '';
    }
  }

  render() {
    const session = this.props.sessionView.session
    const hourMin = formatDeliveryDateHourMinute(session.delivery_date).split(":");
    const hour = hourMin.length === 2 ? hourMin[0] : '';
    const minute = hourMin.length === 2 ? hourMin[1] : '';
    let delivery_date = session.delivery_date ? formatDeliveryDate(session.delivery_date) : formatDeliveryDate(Date.now());
    const custom_attributes = this.props.sessionView.session.attributes;

    const activity_session_age = session && session.activity_session_age ? session.activity_session_age : Constants.ACTIVITY_AGE;
    let male_count = 0;
    let female_count = 0;
    let other_count = 0;
    const male_ages = activity_session_age.filter(function (gender) {
      return gender.gender === Constants.GENDER_MALE;
    }).map((age, key) => {
      male_count += age.count;
      return (
        <div className="field-group" key={key}>
          <div className="field">
            <label><span>{age.gender + " Aged " + age.name}</span></label>
            <input data-gender={age.gender} data-id={age.id} className="narrow center"
              placeholder={0} type="number" value={age.count ? age.count : ''} onChange={this.updateAgeField.bind(this)}
              data-step="2"></input>
          </div>
        </div>
      )
    });

    const program_category = Constants.PROGRAM_CATEGORY.map((value, key) => {
      return (
        <div className={"role-div widthAuto " + (session.program_category && session.program_category === value ? 'active' : '')} key={key} onClick={this.updateSelectField.bind(this)} data-id={value} data-field='program_category'>{value}</div>
      )
    })

    const program_type = Constants.PROGRAM_TYPE.map((value, key) => {
      return (
        <div className={"role-div widthAuto " + (session.program_type && session.program_type === value ? 'active' : '')} key={key} onClick={this.updateSelectField.bind(this)} data-id={value} data-field='program_type'>{value}</div>
      )
    })

    const participants_with_disability = Constants.PARTICIPANTS_WITH_DISABILITY.map((value, key) => {
      return (
        <div className="field-group" key={key}>
          <div className="field">
            <label><span>{value.label}</span></label>
            <input className="narrow center" name={value.value} data-field={value.value}
              placeholder={0} type="number" value={session[value.value] ? session[value.value] : ''} onChange={this.updateSelectField.bind(this)}
              data-step="4"></input>
          </div>
        </div>
      )
      /*return (
        <div className={"role-div widthAuto " + (session.participants_with_disability && session.participants_with_disability === value ? 'active' : '')} key={key} onClick={this.updateSelectField.bind(this)} data-id={value} data-field='participants_with_disability'>{value}</div>
      )*/
    })

    const kpi = Constants.KPI.map((value, key) => {
      return (
        <div className={"role-div widthAuto " + (session.kpi && session.kpi === value ? 'active' : '')} key={key} onClick={this.updateSelectField.bind(this)} data-id={value} data-field='kpi'>{value}</div>
      )
    })

    const female_ages = activity_session_age.filter(function (gender) {
      return gender.gender === Constants.GENDER_FEMALE;
    }).map((age, key) => {
      female_count += age.count;
      return (
        <div className="field-group" key={key}>
          <div className="field">
            <label><span>{age.gender + " Aged " + age.name}</span></label>
            <input data-gender={age.gender} data-id={age.id} className="narrow center"
              placeholder={0} type="number" value={age.count ? age.count : ''} onChange={this.updateAgeField.bind(this)}
              data-step="3"></input>
          </div>
        </div>
      )
    });

    const other_ages = activity_session_age.filter(function (gender) {
      return gender.gender === Constants.GENDER_OTHER;
    }).map((age, key) => {
      other_count += age.count;
      return (
        <div className="field-group" key={key}>
          <div className="field">
            <label><span>{age.gender + " Aged " + age.name}</span></label>
            <input data-gender={age.gender} data-id={age.id} className="narrow center"
              placeholder={0} type="number" value={age.count ? age.count : ''} onChange={this.updateAgeField.bind(this)}
              data-step="3"></input>
          </div>
        </div>
      )
    });

    const errors = this.props.sessionView.errors;
    const server_errors = this.props.sessionView.server_errors;
    const server_error_str = server_errors ? server_errors.map((error, key) =>
      <div className="error-img-div" key={key}>
        <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{error}
      </div>
    ) : "";

    return (
      <div>
        <img src="/img/arrow-left.svg" alt="Arrow Left" className="arrow-left big activity clickable" onClick={this.onBackClick.bind(this)} />
        <div className="title">Activity</div>
        {session.age ?
          <div className="list-div">
            <div className="image-circle reltv center huge">
              {session.activity_type.image_url ?
                <img className="img huge" src={session.activity_type.image_url} alt={session.activity_type.name} id="activity-image" />
                : <div className=" inline">
                  <div className="user-logo ">
                    {session.activity_type.abbreviation}
                  </div>
                </div>}
            </div>
            <div className="name center">
              {session.activity_type.name + ' | ' + session.branch.name}
              <br />
              <br />
              <span>{session.age.name}<span className="dots-span"> . </span>{minuteFormat(session.delivery_duration)}</span>
            </div>
          </div>
          : ""}
        <div className="upsert-one-element-div normal-box" id='0'>
          <div className="upsert-one-element" >
            {server_errors ?
              <div className="error-div">
                {server_error_str}
              </div> : ""}
            <h1>Date & Timings</h1>
            {session.age ? "" :
              <div className="field-group">
                <div className="field with-arrow" data-id="branch" onClick={this.toggleSelect.bind(this)}>
                  <div className="inline">
                    <label><span>Club</span></label>
                    <div>{session.branch ? session.branch.name : "Please Select A Club"}</div>
                  </div >
                  <div className="inline arrow-div">
                    <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
                  </div>
                </div>
                {errors && errors.branch ?
                  <div className="error-img-div">
                    <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.branch}
                  </div> : ""}
              </div>}

            {session.age ? "" :
              session.branch &&
              <div className="field-group">
                <div className="field with-arrow" data-id="activity" onClick={this.toggleSelect.bind(this)}>
                  <div className="inline">
                    <label><span>Activity</span></label><br />
                    {session.activity_type ? session.activity_type.image_url ? <div className="image-circle inline"><img className="img small" src={session.activity_type.image_url} alt={session.activity_type.name} /></div>
                      : <div className=" inline">
                        <div className="user-logo small">
                          {session.activity_type.abbreviation}
                        </div>
                      </div> : ""}
                    <div className="inline">{session.activity_type ? session.activity_type.name : "Please Select An Activity"}</div>
                  </div>
                  <div className="inline arrow-div">
                    <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
                  </div>
                </div>
                {errors && errors.activity ?
                  <div className="error-img-div">
                    <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.activity}
                  </div> : ""}
              </div>}
            <div className="field-group">
              <div className="field with-arrow" onClick={this.selectDate.bind(this)}>
                <div className="inline">
                  <label><span>Delivery Date</span></label><br />
                  <div>{delivery_date}</div>
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.onDateChange.bind(this)}
                  />
                </div>

                <div className="inline arrow-div">
                  <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
                </div>
              </div>
            </div>
            <div className="field-group">
              <div className="field">
                <div className="start-time inline">
                  <label><span>Start Time</span></label><br />
                  <select className="inline hour center" onChange={this.onHourChange.bind(this)} value={hour} data-ampm={hour >= 12 ? 'PM' : 'AM'}>
                    <option>Hour</option>
                    <option>00</option>
                    <option>01</option>
                    <option>02</option>
                    <option>03</option>
                    <option>04</option>
                    <option>05</option>
                    <option>06</option>
                    <option>07</option>
                    <option>08</option>
                    <option>09</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                    <option>21</option>
                    <option>22</option>
                    <option>23</option>
                    <option>24</option>
                  </select>
                  <div className="inline colon">:</div>
                  <select className="inline hour center" onChange={this.onMinuteChange.bind(this)} value={minute}>
                    <option>Minutes</option>
                    <option>00</option>
                    <option>15</option>
                    <option>30</option>
                    <option>45</option>
                  </select>
                  {/*<input className="inline am-pm" value={hour>=12 ? 'PM' : 'AM'} readOnly onClick={this.onAmPmChange.bind(this)} ></input>*/}

                  {/*<select className="inline center am-pm" onChange={this.onAmPmChange.bind(this)} value={hour>=12 ? 'PM' : 'AM'}>
                    <option>AM</option>
                    <option>PM</option>
                  </select>*/}
                </div>
              </div>
            </div>
            <div className="field-group">
              <div className="field">
                <label><span>Duration (minutes)</span></label>
                <input className="narrow center" placeholder={0}
                  data-field="delivery_duration" type="number"
                  value={session.delivery_duration ? session.delivery_duration : ''} onChange={this.updateNumberField.bind(this)}
                  data-step="0"></input>
              </div>
            </div>
            <div className="field-group">
              <div className="field">
                <label><span>Coordination Duration (minutes)</span></label>
                <input className="narrow center" placeholder={0}
                  data-field="overhead_duration" type="number"
                  value={session.overhead_duration ? session.overhead_duration : ''} onChange={this.updateNumberField.bind(this)}
                  data-step="0"></input>
              </div>
            </div>
            {this.state.type === Constants.TYPE_DIRECT ?
              <div className="field-group">
                <div className="field">
                  <label><span>Program Category Attendance</span></label>
                  <div className="role attributes">
                    {program_category}
                  </div>
                </div>
              </div>
              : ""}
            {this.state.type === Constants.TYPE_DIRECT ?
              <div className="field-group">
                <div className="field">
                  <label><span>Program Type</span></label>
                  <div className="role attributes">
                    {program_type}
                  </div>
                </div>
              </div>
              : ""}
            {this.state.type === Constants.TYPE_DIRECT ?
              <div className="field-group">
                <div className="field">
                  <label><span>Social Outcomes & Key Results</span></label>
                  <div className="role attributes">
                    {kpi}
                  </div>
                </div>
              </div>
              : ""}
          </div>
        </div>
        {this.state.type === Constants.TYPE_DIRECT ?
          <div className="upsert-one-element-div normal-box" id='1'>
            <div className="upsert-one-element" >
              <h1>Participant Ethnicity</h1>
              {errors && errors.total ?
                <div className="error-div">
                  {errors.total}
                </div> : ""}
              <label className="total-count">Total Count: {(session.australian ? session.australian : 0) + (session.aboriginal ? session.aboriginal : 0) + (session.torres ? session.torres : 0) + (session.asti ? session.asti : 0) + (session.cald ? session.cald : 0) + (session.other ? session.other : 0) + (session.volunteers ? session.volunteers : 0)}</label>
              <div className="field-group">
                <div className="field">
                  <label><span>Aboriginal</span></label>
                  <input className="narrow center" placeholder="0"
                    data-field="aboriginal" type="number" value={session.aboriginal ? session.aboriginal : ''} onChange={this.updateNumberField.bind(this)}
                    data-step="1"></input>
                </div>
              </div>
              <div className="field-group">
                <div className="field">
                  <label><span>Caucasian</span></label>
                  <input className="narrow center" placeholder="0"
                    data-field="australian" type="number" value={session.australian ? session.australian : ''} onChange={this.updateNumberField.bind(this)}
                    data-step="1"></input>
                </div>
              </div>
              <div className="field-group">
                <div className="field">
                  <label><span>Torres Strait Islander</span></label>
                  <input className="narrow center" placeholder="0"
                    data-field="torres" type="number" value={session.torres ? session.torres : ''} onChange={this.updateNumberField.bind(this)}
                    data-step="1"></input>
                </div>
              </div>

              <div className="field-group">
                <div className="field">
                  <label><span>Aboriginal & Torres Strait Islander</span></label>
                  <input className="narrow center" placeholder="0"
                    data-field="asti" type="number" value={session.asti ? session.asti : ''} onChange={this.updateNumberField.bind(this)}
                    data-step="1"></input>
                </div>
              </div>
              <div className="field-group">
                <div className="field">
                  <label><span>CALD (Optional - specify CALD in Notes)</span></label>
                  <input className="narrow center" placeholder="0"
                    type="number" data-field="cald" value={session.cald ? session.cald : ''} onChange={this.updateNumberField.bind(this)}
                    data-step="1"></input>
                </div>
              </div>
              <div className="field-group">
                <div className="field">
                  <label><span>Prefer not to identify</span></label>
                  <input className="narrow center" placeholder="0"
                    type="number" data-field="other" value={session.other ? session.other : ''} onChange={this.updateNumberField.bind(this)}
                    data-step="1"></input>
                </div>
              </div>
              <div className="field-group">
                <div className="field">
                  <label><span>Volunteers</span></label>
                  <input className="narrow center" placeholder={0} data-field="volunteers"
                    type="number" value={session.volunteers ? session.volunteers : ''} onChange={this.updateNumberField.bind(this)}
                    data-step="1" ></input>
                </div>
              </div>
            </div>
          </div>
          : ""}
        {this.state.type === Constants.TYPE_DIRECT ?
          <div className="upsert-one-element-div normal-box" id='2'>
            <div className="upsert-one-element" >
              <h1>Male Attendance</h1>
              {errors && errors.age ?
                <div className="error-div">
                  {errors.age}
                </div> : ""}
              <label className="total-count">Total Count: {male_count}</label>
              {male_ages}
            </div>
          </div>
          : ""}
        {this.state.type === Constants.TYPE_DIRECT ?
          <div className="upsert-one-element-div normal-box" id='3'>
            <div className="upsert-one-element" >
              <h1>Female Attendance</h1>
              {errors && errors.age ?
                <div className="error-div">
                  {errors.age}
                </div> : ""}
              <label className="total-count">Total Count: {female_count}</label>
              {female_ages}
            </div>
          </div>
          : ""}
        {this.state.type === Constants.TYPE_DIRECT ?
          <div className="upsert-one-element-div normal-box" id='3'>
            <div className="upsert-one-element" >
              <h1>Other Attendance</h1>
              {errors && errors.age ?
                <div className="error-div">
                  {errors.age}
                </div> : ""}
              <label className="total-count">Total Count: {other_count}</label>
              {other_ages}
            </div>
          </div>
          : ""}
        <div className="upsert-one-element-div normal-box" id={this.props.type === Constants.TYPE_DIRECT ? 4 : 1}>
          <div className="upsert-one-element" >
            {this.state.type === Constants.TYPE_DIRECT ?
              <h1>Participants with Disability & Notes</h1> : <h1>Notes</h1>
            }
            {this.state.type === Constants.TYPE_DIRECT ?
              <div className="field-group">
                <div className="field">
                  <label><span>Participants with Disability</span></label>
                  {participants_with_disability}
                </div>
              </div> : ''
            }
            <div className="field-group">
              <div className="field">
                <label><span>Notes</span></label>
                <textarea className="narrow" placeholder="Notes" data-field="notes"
                  value={session.notes ? session.notes : ""} onChange={this.updateTextField.bind(this)}
                  data-step={this.props.type === Constants.TYPE_DIRECT ? 4 : 1}></textarea>
              </div>
            </div>
          </div>

          {
            custom_attributes && custom_attributes.length > 0 && custom_attributes.length !== custom_attributes.filter(attribute => attribute.archived).length ?
              (
                <div className="upsert-one-element">
                  <h1>Custom Attributes</h1>
                  {custom_attributes.map((attribute, key) => {
                    if (!attribute.archived)
                      return (<div className="field-group" key={key}>
                        <div className="field">
                          <label><span>{attribute.title}</span></label>
                          {this.renderSwitch(attribute)}
                        </div>
                      </div>)
                    else return <></>
                  })}
                </div>
              ) : ''
          }

          {errors && errors.total ?
            <div className="error-div">
              {errors.total}
            </div> : ""}
        </div>
        <button className="white-red-button session" onClick={this.upsertSession.bind(this)}>Submit</button>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogSessionForm);
