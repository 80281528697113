import * as Constants from '../Constants';

export const pageChange = (page) => ({ type: Constants.PAGE_CHANGE, page: page });
export const pageChangeWithNoStateChange = (page) => ({ type: Constants.PAGE_CHANGE_WITH_NO_STATE_CHANGE, page: page });
export const fetchingStart = () => ({ type: Constants.FETCHING_START });
export const fetchingEnd = () => ({ type: Constants.FETCHING_END });
export const toggleSelect = (view_type = null, id = null, id2 = null) => ({ type: Constants.TOGGLE_SELECT, view_type: view_type, id: id, id2: id2 });
export const closeImage = (view) => ({ type: Constants.CLOSE_IMAGE, view: view });
export const updatePreviewFile = (result, view) => ({ type: Constants.UPDATE_PREVIEW_FILE, result: result, view: view });
export const updateField = (field, view, data) => ({ type: Constants.UPDATE_FIELD, field: field, view: view, data: data });
export const updateAttributeField = (field, view, data) => ({ type: Constants.UPDATE_CUSTOM_FIELD, field: field, view: view, data: data });


export function previewFile(file_id, view) {
    return dispatch => {
        var file = document.getElementById(file_id).files[0];
        var reader = new FileReader();

        reader.onload = function() {
            dispatch(updatePreviewFile(reader.result, view));

        }
        if (file) {
            reader.readAsDataURL(file);
        }
    }
}