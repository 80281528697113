import React from 'react';
import { connect } from "react-redux";
import GoodNewsList from './GoodNewsList';
import { fetchBranchList } from '../../action/branch';
import { fetchGoodNewsList, deleteAGoodNews } from '../../action/goodNews';
import * as Constants from '../../Constants';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    fetchBranchList,
    fetchGoodNewsList,
    deleteAGoodNews
};

class GoodNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            branch_id: '',
            str: "",
            page: 1,
            endPage: false
        }
        this.props.fetchBranchList();
        this.props.fetchGoodNewsList();
    }


    loadMore() {
        let nextPage = this.state.page + 1;
        let currentCount = this.state.page * Constants.MAX_COUNT;
        let nextCount = nextPage * Constants.MAX_COUNT;

        if (this.state.endPage !== true) {
            if (nextCount < this.props.goodNewsView.total_count) {
                this.setState({
                    ...this.state,
                    page: nextPage
                });
            } else {
                nextCount = this.props.goodNewsView.total_count;
                this.setState({
                    ...this.state,
                    page: nextPage,
                    endPage: true
                });
            }
            // fetch survey list again
            this.props.fetchGoodNewsList(null, this.state.branch_id, this.state.str, currentCount, Constants.MAX_COUNT);
        }
    }

    handleDelete(good_news_id) {
        // after delete, the survey list need to reload using the same data from the previous page
        this.props.deleteAGoodNews(good_news_id);
      }

    render() {
        return (
            <div className="mobile-goodnews">
                <GoodNewsList loadMore={this.loadMore.bind(this)} handleDelete={this.handleDelete.bind(this)}></GoodNewsList>
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GoodNews);
