import * as Constants from '../Constants';

const sessionView = (state = Constants.sessionViewInitialState, action) => {
    let session = {};
    switch (action.type) {
        case Constants.RECEIVE_SESSION_LIST:
            return {
                sessionList: action.sessionList,
                session: state.session,
                total_count: action.total_count
            }
        case Constants.FETCH_A_SESSION:
            let returnSession = {};
            for (var i = 0; i < state.sessionList.length; i++) {
                session = state.sessionList[i];
                if (session.id === parseInt(action.id)) {
                    returnSession = session;
                    break;
                }
            }
            return {
                sessionList: state.sessionList,
                session: {
                    ...returnSession
                },
                total_count: state.total_count
            }
        case Constants.SELECT_SESSION_BRANCH:
            return {
                sessionList: state.sessionList,
                session: {
                    ...state.session,
                    branch: {
                        id: action.branch.id,
                        name: action.branch.name
                    },
                    branch_id: action.branch.id
                },
                total_count: state.total_count
            }
        case Constants.SELECT_SESSION_ACTIVITY:
            return {
                sessionList: state.sessionList,
                session: {
                    ...state.session,
                    activity_type: action.activity,
                    activity_type_id: action.activity.id
                },
                total_count: state.total_count
            }
        case Constants.GET_SESSION_CUSTOM_ATTRIBUTE:
            return {
                sessionList: state.sessionList,
                session: {
                    ...state.session,
                    attributes: action.attributes
                },
                total_count: state.total_count
            }
        case Constants.UPDATE_FIELD:
            if (action.view === Constants.SESSION_VIEW) {
                session = {
                    ...state.session,
                }
                session[action.field] = action.data;
                return {
                    sessionList: state.sessionList,
                    session: session,
                    total_count: state.total_count
                }
            }
            return state;
        case Constants.UPDATE_CUSTOM_FIELD:
            if (action.view === Constants.SESSION_VIEW) {
                session = {
                    ...state.session,
                }
                let affectedAttribute = {};
                let answer = [];
                if(session.attributes && session.attributes.length > 0) {
                    affectedAttribute = session.attributes.find(x => x.id ===  parseInt(action.field));
                    answer = affectedAttribute.answer;
                } else {
                    affectedAttribute = session.attribute_activity_session.find(x => x.id ===  parseInt(action.field));
                    switch (affectedAttribute.attribute_type_id) {
                        case 1:
                        case 2:
                            if(affectedAttribute.pivot.option_select_answer) {
                                answer = affectedAttribute.pivot.option_select_answer.split(',').map(Number);
                            }
                            
                            break;

                        case 3:
                            if(affectedAttribute.pivot.true_false_answer) {
                                answer = [parseInt(affectedAttribute.pivot.true_false_answer)];
                            }
                            break;

                        case 4:
                            if(affectedAttribute.pivot.free_text_answer) {
                                answer = [affectedAttribute.pivot.free_text_answer];
                            }
                            break;
                    
                        default:
                            if(affectedAttribute.pivot.free_text_answer) {
                                answer = [affectedAttribute.pivot.free_text_answer];
                            }
                            break;
                    }
                }
                
                if(answer && answer.length > 0 && affectedAttribute.attribute_type_id === 1) {
                    if(answer.includes(parseInt(action.data))) {
                        answer = answer.filter((item) => item !== parseInt(action.data));
                    } else {
                        answer.push(parseInt(action.data));
                    }
                } else {
                    if(affectedAttribute.attribute_type_id === 4) {
                        answer = [action.data];
                    } else {
                        answer = [parseInt(action.data)];
                    }
                }

                if(session.attributes && session.attributes.length > 0) {
                    affectedAttribute.answer = answer;
                } else {
                    switch (affectedAttribute.attribute_type_id) {
                        case 1:
                        case 2:
                            affectedAttribute.pivot.option_select_answer = answer.join(',');
                            
                            break;

                        case 3:
                            affectedAttribute.pivot.true_false_answer = answer.join('');
                            break;

                        case 4:
                            affectedAttribute.pivot.free_text_answer = answer.join('');
                            break;
                    
                        default:
                            affectedAttribute.pivot.free_text_answer = answer.join('');
                            break;
                    }
                }

                return {
                    sessionList: state.sessionList,
                    session: session,
                    total_count: state.total_count
                }
            }
            return state;
        case Constants.RECEIVE_UPDATE_SESSION:
            for (var i2 = 0; i2 < state.sessionList.length; i2++) {
                session = state.sessionList[i2];
                if (session.id === parseInt(action.session.id)) {
                    state.sessionList[i2] = action.session
                    break;
                }
            }
            return {
                sessionList: state.sessionList,
                session: action.session,
                total_count: state.total_count
            }
        case Constants.PAGE_CHANGE:
            return {
                sessionList: state.sessionList,
                session: {},
                total_count: state.total_count
            }
        case Constants.SHOW_INLINE_ERROR_MSG:
            return {
                sessionList: state.sessionList,
                session: state.session,
                errors: action.errors,
                total_count: state.total_count
            }

        case Constants.SHOW_SERVER_ERROR_MSG:
            return {
                sessionList: state.sessionList,
                session: state.session,
                server_errors: action.errors,
                total_count: state.total_count
            }
        case Constants.RECEIVE_CREATE_SESSION:
            return {
                add_success: true,
                message: action.message,
                sessionList: state.sessionList,
                session: action.session
            }
        case Constants.RECEIVE_DELETE_A_SESSION:
            return {
                sessionList: state.sessionList.filter(function(session) {
                    return session.id !== parseInt(action.id);
                }),
                session: state.session,
                total_count: state.total_count - 1
            }
        case Constants.FETCH_ONE_SESSION_FROM_WORKPLAN:
            return {
                ...state,
                session: {
                    branch_id: action.workplanActivity.workplan.branch_id,
                    branch: action.workplanActivity.workplan.branch,
                    activity_type_id: action.workplanActivity.activity_type_id,
                    activity_type: action.workplanActivity.activity_type,
                    delivery_duration: action.workplanActivity.delivery_duration,
                    age: action.workplanActivity.age,
                    attributes: action.workplanActivity.custom_attributes.attributes
                }
            }
        default:
            return state;
    }
}

export default sessionView;