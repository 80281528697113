import React from 'react';
import { connect } from "react-redux";
import { fetchActivityList } from '../../action/activity';
import LogSessionForm from './LogSessionForm';
import SelectBranch from './SelectBranch';
import SelectActivity from './SelectActivity';
import AddSuccess from './AddSuccess';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    fetchActivityList
};

class AddSession extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type
        }
        this.props.fetchActivityList();
    }

    render() {
        return (
            <div className="AddSession">
                {this.props.sessionView.add_success ? <AddSuccess></AddSuccess> :
                    <div>
                        {this.props.root.overlay ?
                            this.props.root.view_type === 'branch' ? <SelectBranch></SelectBranch> :
                                this.props.root.view_type === 'activity' ? <SelectActivity type={this.state.type}></SelectActivity> :
                                    <LogSessionForm type={this.state.type} /> : <LogSessionForm type={this.state.type} />}
                    </div>
                }
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSession);
