import * as Constants from '../Constants';

const attributeView = (state = Constants.attributeViewInitialState, action) => {
    switch (action.type) {
        case Constants.RECEIVE_BRANCH_LIST:
            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: action.branchList,
                attributeList: state.attributeList,
                attribute: state.attribute
            }
        case Constants.RECEIVE_ATTRIBUTE_LIST:
            return {
                add_success: false,
                block: state.block,
                total_count: action.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                attributeList: action.attributeList,
                attribute: state.attribute
            }
        case Constants.FETCH_ATTRIBUTE:
            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                attributeList: state.attributeList,
                attribute: action.attribute
            }
        case Constants.ATTRIBUTE_CHANGE:
            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                attributeList: state.attributeList,
                attribute: action.attribute
            }
        case Constants.ATTRIBUTE_ACTIVITY_CHANGE:
            let currentAttribute = state.attribute;
            currentAttribute.activities = action.activities;

            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                attributeList: state.attributeList,
                attribute: currentAttribute
            }
        case Constants.ATTRIBUTE_BRANCH_CHANGE:
            let currentAttributeb = state.attribute;
            currentAttributeb.branches = action.branches;

            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                attributeList: state.attributeList,
                attribute: currentAttributeb
            }
        case Constants.RECEIVE_UPDATE_ATTRIBUTE:
            for (var i2 = 0; i2 < state.attributeList.length; i2++) {
                let attribute = state.attributeList[i2];
                if (attribute.id === parseInt(action.attribute.id)) {
                    state.attributeList[i2].title = action.attribute.title;
                    state.attributeList[i2].description = action.attribute.description;
                    state.attributeList[i2].attached_to = action.attribute.attached_to;
                    state.attributeList[i2].attribute_type_id = action.attribute.attribute_type_id;
                    state.attributeList[i2].attribute_options = action.attribute.attribute_options;
                    state.attributeList[i2].activities = action.attribute.activities;
                    state.attributeList[i2].branches = action.attribute.branches;
                    break;
                }
            }
            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                attributeList: state.attributeList,
                attribute: action.attribute
            }
        case Constants.HOVER_ENTER:
            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: parseInt(action.hover_id),
                branchList: state.branchList,
                attributeList: state.attributeList,
                attribute: state.attribute
            }
        case Constants.HOVER_LEAVE:
            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: 0,
                branchList: state.branchList,
                attributeList: state.attributeList,
                attribute: state.attribute
            }
        case Constants.SHOW_SERVER_ERROR_MSG:
            return {
                add_success: false,
                block: state.block,
                server_errors: action.errors,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                attributeList: state.attributeList,
                attribute: state.attribute
            }
        case Constants.PAGE_CHANGE:
            return {
                add_success: false,
                block: {
                    isBlocking: false,
                    blockOpen: false,
                    block_id: 0
                },
                message: "",
                total_count: 0,
                hover_id: 0,
                branchList: state.branchList,
                attributeList: state.attributeList,
                attribute: {}
            }
        case Constants.UPDATE_MODAL:
            return {
                add_success: false,
                block: action.block,
                server_errors: state.errors,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                attributeList: state.attributeList,
                attribute: state.attribute
            }
        case Constants.RECEIVE_CREATE_ATTRIBUTE:
            return {
                add_success: true,
                block: state.block,
                message: action.message,
                total_count: state.total_count,
                hover_id: action.attribute.id,
                branchList: state.branchList,
                attributeList: state.attributeList,
                attribute: action.attribute
            }
        case Constants.RECEIVE_DELETE_A_ATTRIBUTE:
            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: state.attribute.id,
                branchList: state.branchList,
                attributeList: state.attributeList,
                attribute: {}
            }
        default:
            return state;
    }
}

export default attributeView;