import React from 'react';
import { connect } from "react-redux";
import { fetchAGoodNews } from '../../action/goodNews';
import Modal from 'react-responsive-modal';
import { timeDifference } from '../../utils/date';
import { toggleSelect } from '../../action/view';
import CreateGoodNews from './CreateGoodNews';
import GoodNewsForm from './GoodNewsForm';
import SelectBranch from './SelectBranch';
import * as Constants from '../../Constants';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  fetchAGoodNews,
  toggleSelect
};
class GoodNewsList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      editBox: 0
    };
  }

  toggleEditBox(e) {
    this.setState({
      open: this.state.open,
      editBox: parseInt(e.currentTarget.getAttribute('data-id'))
    })

  }

  handleClick(e) {
    if(!e.target.getAttribute('data-type')){
      this.setState({
          open: this.state.open,
          editBox: 0
        })
    }
  }

  componentDidMount() {
    // Detect when scrolled to bottom.
    this.refs.scroll.addEventListener("scroll", () => {
      if (
        this.refs.scroll.scrollTop + this.refs.scroll.clientHeight >=
        this.refs.scroll.scrollHeight
      ) {
        this.props.loadMore();
      }
    });

    document.addEventListener("mousedown", this.handleClick.bind(this));


  }

  componentWillUnmount() {
    // return function to be called when unmounted
    document.removeEventListener("mousedown", this.handleClick.bind(this));
  }

  handDelete(e) {
    e.preventDefault();
    this.props.handleDelete(this.state.id);
    this.onCloseModal();
  }

  handleEdit(e) {
    e.preventDefault();
    this.props.fetchAGoodNews(e.currentTarget.getAttribute('data-id'));
    this.props.toggleSelect('update')
  }

  onOpenModal = (e) => { this.setState({ open: true, editBox: this.state.editBox, id: e.currentTarget.getAttribute('data-id') }) };
  onCloseModal = () => { this.setState({ open: false, editBox: this.state.editBox }) };


  render() {
    const user = JSON.parse(localStorage.getItem('user')).user;
    const branch = user.branch.map(branch => branch.id)
    const goodNewsList = this.props.goodNewsView.goodNewsList;
    const goodNewses = goodNewsList.map((goodNews, key) => {
      return (
        <div className="list-div normal-box reltv" key={key}>
          <div className="user-logo-div inline">
            <div className="user-logo">
              {goodNews.user.first_name.charAt(0) + goodNews.user.last_name.charAt(0)}
            </div>
          </div>
          <div className="name inline">
            {goodNews.user.first_name + ' ' + goodNews.user.last_name}
            <br />
            <span>{timeDifference(goodNews.updated_at)}<span className="dots-span"> . </span>{goodNews.branch.name}</span>
          </div>
          {(user.role_id < Constants.GUEST && user.role_id >  Constants.ADMIN &&  branch.includes(goodNews.branch_id)) || user.role_id <= Constants.ADMIN ? 
            <img src="/img/dot-black.svg" alt="Dot Black" className="dot-black float-right clickable" data-id={goodNews.id} onClick={this.toggleEditBox.bind(this)}></img>
          :""}
          {this.state.editBox === goodNews.id ?
            <div className="edit-box ab" ref="node" data-type="1">
              <p className="clickable" data-type="1" data-id={goodNews.id} onClick={this.handleEdit.bind(this)}>Edit</p>
              <p className="clickable" data-type="1" data-id={goodNews.id} onClick={this.onOpenModal.bind(this)}>Delete</p>
            </div> : ""}
          <div className="feedContent">
            <div className="feedThumb">
              <img className="feedThumbContainer" src={goodNews.image_url} alt={goodNews.title}></img>
            </div>
            <div className="feedTitle">
              <p><strong>{goodNews.title}</strong></p>
            </div>
            <div className="feedExcerpt">
              <p>{goodNews.description}</p>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className="manage-div" ref="scroll">
        {this.props.root.overlay ? 
          this.props.root.view_type === 'create' ? <GoodNewsForm action="create"></GoodNewsForm> : 
          this.props.root.view_type === 'update' ? <GoodNewsForm action="update" ></GoodNewsForm> : 
          this.props.root.view_type === 'createbranch' ? <SelectBranch action="create"></SelectBranch> : 
          this.props.root.view_type === 'updatebranch' ? <SelectBranch action="update"></SelectBranch> : 
          <CreateGoodNews></CreateGoodNews> : <CreateGoodNews></CreateGoodNews>}
        <div className="manage-list" >
          {goodNewses}
        </div>
        <div className='placeholder'></div>
        <Modal open={this.state.open} onClose={this.onCloseModal.bind(this)} center>
          <div className="userModal">
            <p>Are you sure you want to delete this Social Impact</p>
            <div className="center">
            <button className="yesButton" onClick={this.handDelete.bind(this)}>Yes</button>
              <button className="cancelButton" onClick={this.onCloseModal.bind(this)}>Cancel</button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoodNewsList);
