import React from 'react';
import { connect } from "react-redux";
import { toggleSelect, closeImage, previewFile, updateField, updateAttributeField } from '../../action/view';
import { upsertGoodNews } from '../../action/goodNews';
import * as Constants from '../../Constants';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  toggleSelect,
  previewFile,
  closeImage,
  updateField,
  updateAttributeField,
  upsertGoodNews
};

class CreateGoodNews extends React.Component {

  toggleSelect(e) {
    this.props.toggleSelect();
  }

  toggleSelectBranch(e) {
    this.props.toggleSelect();
    this.props.toggleSelect(this.props.action + 'branch');
  }

  handleImageClose(e) {
    e.preventDefault();
    this.props.closeImage(Constants.GOOD_NEWS_VIEW);
  }


  handleImageUpload(e) {
    e.preventDefault();
    document.getElementById("goodNews-image-file").click();
  }

  previewFile(e) {
    e.preventDefault();
    this.props.previewFile('goodNews-image-file', Constants.GOOD_NEWS_VIEW);
  }

  updateTextField(e) {
    e.preventDefault();
    this.props.updateField(e.currentTarget.getAttribute('data-field'), Constants.GOOD_NEWS_VIEW, e.currentTarget.value);
  }

  upsertGoodNews(e) {
    e.preventDefault();
    this.props.upsertGoodNews(this.props.goodNewsView.goodNews, this.props.action);
  }

  updateSelectAttribute(e) {
    e.preventDefault();
    let field = e.currentTarget.getAttribute('data-field');
    let data = e.currentTarget.getAttribute('data-id');

    if (data) {
      this.props.updateAttributeField(field, Constants.GOOD_NEWS_VIEW, data);
    } else {
      this.props.updateAttributeField(field, Constants.GOOD_NEWS_VIEW, e.currentTarget.value);
    }
  }

  renderSwitch(attribute) {
    let answer = [];
    let currentFilledAttributes = this.props.goodNewsView.goodNews.attribute_goodnews;
    if (attribute.pivot) {
      if (attribute.pivot.free_text_answer) {
        answer = [attribute.pivot.free_text_answer];
      } else if (attribute.pivot.option_select_answer) {
        answer = attribute.pivot.option_select_answer.split(',').map(Number);
      } else if (attribute.pivot.true_false_answer) {
        answer = [parseInt(attribute.pivot.true_false_answer)];
      }
    } else {
      if (currentFilledAttributes && currentFilledAttributes.length > 0) {
        let currentFilledAttribute = currentFilledAttributes.find(element => parseInt(element.id) === parseInt(attribute.id));
        if (currentFilledAttribute && !attribute.answer) {
          if (currentFilledAttribute.pivot.free_text_answer) {
            answer = [currentFilledAttribute.pivot.free_text_answer];
          } else if (currentFilledAttribute.pivot.option_select_answer) {
            answer = currentFilledAttribute.pivot.option_select_answer.split(',').map(Number);
          } else if (currentFilledAttribute.pivot.true_false_answer) {
            answer = [parseInt(currentFilledAttribute.pivot.true_false_answer)];
          }
        } else {
          answer = attribute.answer;
        }
      } else {
        answer = attribute.answer;
      }
    }
    switch (attribute.attribute_type_id) {
      case 1: // multi select
        return (
          <div className="role attributes">
            {
              attribute.attribute_options.map((option, key) => {
                return (
                  <div className={"role-div " + (answer && answer.includes(option.id) ? 'active' : '')} key={key} onClick={this.updateSelectAttribute.bind(this)} data-id={option.id} data-field={attribute.id} data-type="Int">{option.option}</div>
                )
              })
            }
          </div>
        );
      case 2: // single select
        return (
          <div className="role attributes">
            {
              attribute.attribute_options.map((option, key) => {
                return (
                  <div className={"role-div " + (answer && answer.includes(option.id) ? 'active' : '')} key={key} onClick={this.updateSelectAttribute.bind(this)} data-id={option.id} data-field={attribute.id} data-type="Int">{option.option}</div>
                )
              })
            }
          </div>
        );
      case 3: // yes no question
        return (
          <div className="role attributes">
            <div className={"role-div " + (answer && answer.includes(1) ? 'active' : '')} data-id="1" onClick={this.updateSelectAttribute.bind(this)} data-field={attribute.id} data-type="Int">Yes</div>
            <div className={"role-div " + (answer && answer.includes(0) ? 'active' : '')} data-id="0" onClick={this.updateSelectAttribute.bind(this)} data-field={attribute.id} data-type="Int">No</div>
          </div>
        );
      case 4:
        return (
          <input className="narrow" data-field={attribute.id}
            value={answer ? answer[0] : ''} onChange={this.updateSelectAttribute.bind(this)}
            data-step={this.props.type === Constants.TYPE_DIRECT ? 4 : 1}></input>
        );
      default:
        return '';
    }
  }

  render() {
    const user = JSON.parse(localStorage.getItem('user')).user;
    const goodNews = this.props.goodNewsView.goodNews;
    const custom_attributes = goodNews.attribute_goodnews && !goodNews.custom_attributes ? goodNews.attribute_goodnews : goodNews.custom_attributes;
    const url = goodNews.image_base_64 ? goodNews.image_base_64 : (goodNews.image_url ? goodNews.image_url : null)
    const errors = this.props.goodNewsView.errors;
    const server_errors = this.props.goodNewsView.server_errors;
    const server_error_str = server_errors ? server_errors.map((error, key) =>
      <div className="error-img-div" key={key}>
        <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{error}
      </div>
    ) : "";
    return (
      <div className="goodnewsform upsert-one-element-div normal-box">
        <div className="upsert-one-element" >
          {server_errors ?
            <div className="error-div">
              {server_error_str}
            </div> : ""}
          <h1 className="clickable" onClick={this.toggleSelect.bind(this)}><img src="/img/arrow-left.svg" alt="Arrow Left" className="arrow-left"></img>{this.props.action === 'update' ? 'Update' : "Create"} Social Impact</h1>
          <div className="user-logo-div">
            <div className="user-logo inline">{user.first_name.charAt(0) + user.last_name.charAt(0)}</div>
            <textarea className="inline description" placeholder="Share the Social Impact..." data-field="description" onChange={this.updateTextField.bind(this)} value={goodNews && goodNews.description ? goodNews.description : ""}></textarea>
            {errors && errors.description ?
              <div className="error-img-div">
                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.description}
              </div> : ""}
          </div>
          <div className="field-group">
            <div className="field">
              <label className="inline">Title</label>
              <input className="inline" placeholder="What's the title of your story?" data-field="title" onChange={this.updateTextField.bind(this)} value={goodNews && goodNews.title ? goodNews.title : ""}></input>
            </div>
            {errors && errors.title ?
              <div className="error-img-div">
                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.title}
              </div> : ""}
          </div>
          <div className="field-group">
            <div className="field reltv">
              <label className="inline">Club</label>
              <input className="inline clickable" placeholder="Select Club" readOnly onClick={this.toggleSelectBranch.bind(this)} value={goodNews && goodNews.branch_id ? goodNews.branch.name : ""}></input>
              <img src="/img/arrow.svg" alt="arrow" className="arrow ab"></img>
            </div>
            {errors && errors.branch ?
              <div className="error-img-div">
                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.branch}
              </div> : ""}
          </div>
          {url ?
            <div className="image-square reltv">
              <img className="img big" src={url} alt='news' id="goodNews-image" />
              <div className="image-overlay ab"></div>
              <img src='/img/image-close.svg' alt="Close" className="image-close clickable" onClick={this.handleImageClose.bind(this)} />
            </div>
            :
            <div className="photo clickable inline" onClick={this.handleImageUpload.bind(this)}>
              <img src="/img/photo.svg" className="photo-icon inline" alt="Icon"></img>
              <h1 className="inline">Photo</h1>
            </div>
          }
          {errors && errors.image ?
            <div className="error-img-div">
              <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.image}
            </div> : ""}
          <input type="file" id='goodNews-image-file' onChange={this.previewFile.bind(this)} hidden></input>
          {
            custom_attributes && custom_attributes.length > 0 ?
              (
                <>
                  <h1>Custom Attributes</h1>
                  {custom_attributes.map((attribute, key) => {
                    if (!attribute.archived)
                      return (<div className="field-group" key={key}>
                        <div className="field">
                          <label className="inline">{attribute.title}</label>
                          {this.renderSwitch(attribute)}
                        </div>
                      </div>)
                    return <></>
                  })}
                </>
              ) : ''
          }
          <button className="white-red-button" onClick={this.upsertGoodNews.bind(this)}>{this.props.action === 'update' ? 'Update' : "Share"}</button>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateGoodNews);
