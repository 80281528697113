import React from 'react';
import * as Constants from '../Constants';
import { connect } from "react-redux";
import { pageChange } from '../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageChange
};

class Menu extends React.Component {

      handleClick(e){
        this.props.pageChange(e.currentTarget.getAttribute('data-id'))
      }

    render() {
        let page = this.props.root.page;
        return (
            <div className="menu">
                <div className={"clickable menu-item inline " + (page === Constants.ACTIVITIES ? "active" : "")} onClick={this.handleClick.bind(this)} data-id={Constants.ACTIVITIES}>
                    <img src={"/img/activities"+(page === Constants.ACTIVITIES ? "_active" : "") +".svg"} alt={Constants.ACTIVITIES}></img>
                    <p>{Constants.ACTIVITIES}</p>
                </div>
                <div className={"clickable menu-item inline " + (page === Constants.SURVEYS ? "active" : "")} onClick={this.handleClick.bind(this)} data-id={Constants.SURVEYS}>
                    <img src={"/img/surveys"+(page === Constants.SURVEYS ? "_active" : "") +".svg"} alt={Constants.SURVEYS}></img>
                    <p>{Constants.SURVEYS}</p>
                </div>
                <div className={"clickable menu-item inline " + (page === Constants.GOOD_NEWS ? "active" : "")} onClick={this.handleClick.bind(this)} data-id={Constants.GOOD_NEWS}>
                    <img src={"/img/good_news"+(page === Constants.GOOD_NEWS ? "_active" : "") +".svg"} alt={Constants.GOOD_NEWS}></img>
                    <p>{Constants.GOOD_NEWS}</p>
                </div>
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Menu);
